<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
          <h1 class="title">Orientation </h1>
          <h6 class="subtitle op-8">
            Conditions générales d'utilisation
          </h6>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>

  <div class="container spacer" style="color: black !important;">
    <h3>
        CONDITIONS GENERALES D’UTILISATION (CGU)TREIZE EDUCATION (13.EDU)
   </h3>
   <p>Dénomination sociale : Treize Education (ci-après dénommée 13.Edu) </p>
   
   <p>
    <span class="text-bold"> Editeur de l’application : </span>
   TREIZE CONSULTING, société à responsabilité limitée
unipersonnel au capital de 1000 000 Franc CFA, dont le siège social est situé Abidjan,
Cocody, Angré 8ème Tranche, Carrefour prière, lot : 682, ILOT 44, inscrite au Registre
du Commerce et du crédit mobilier sous le numéro RCCM CI-ABJ-03-2024-B13-05198.
<br><span class="text-bold">   Hébergeur  </span>: TREIZE CONSULTING, société à responsabilité limitée unipersonnel au
capital de 1000 000 Franc CFA, dont le siège social est situé Abidjan, Cocody, Angré
8ème Tranche, Carrefour prière, lot : 682, ILOT 44, inscrite au Registre du Commerce et
du crédit mobilier sous le numéro RCCM CI-ABJ-03-2024-B13-05198.
   </p>
   <br>
   <p>Merci de lire avec attention les différentes conditions d’utilisation de la présente
    application avant d’y accéder. En cliquant OK ou ACCEPTER ou en accédant à cette
    application, vous acceptez, sans réserve, les présentes modalités.</p>
   <section>
   <h3>
    Article 1 : OBJET
   </h3>
   <p>
    Les présentes conditions Générales fixent les modalités d'utilisation de l’application
    mobile 13.Edu que nous vous mettons à disposition.
    Dans les présentes, les expressions "Nous" (et par extension "notre", "nos") désigne
    13.Edu et "Vous" (et par extension "votre", "vos") désigne la personne physique ayant téléchargé
    et installé l’application mobile 13.Edu.
    Elles fixent les responsabilités de chacun dans le fonctionnement de l’application, mais
    vous renseigne également sur la propriété intellectuelle et la protection de vos données
    personnelles.
    
    
   </p>
   <p>
    En outre, elles déterminent les conditions dans lesquelles vous pouvez accéder à distance
    à vos comptes client, aux informations de nature éducative ou non ainsi que celles dans
    lesquelles vous pouvez réaliser des opérations transactionnelles par le canal digital.
    par le canal digital. 
   </p>
   </section>

   <section>
    <h3>Article 2 : CONSENTEMENT DU DEMANDEUR DU SERVICE</h3>
    <p>Le demandeur reconnait, préalablement à son inscription au service, avoir lu et compris
        l’intégralité des CGU du service via l’Application de 13.Edu</p>
    <p>Le demandeur accepte que 13.Edu, en qualité d’Agrégateur, offre le service uniquement
        par voie électronique via l’application 13.Edu.</p>
   </section>

   <section>
    <h3>Article 3 : ACCES A L’APPLICATION MOBILE TREIZE EDUCATION</h3>
    <p>Pour télécharger, installer et utiliser l’application mobile 13.Edu, vous devez posséder
        un mobile compatible Android IPhone IOS et un accès au réseau Internet.
        </p>
    <p>L’application est téléchargeable gratuitement (hors coûts d’abonnement téléphonique, de
        connexion et d’accès au réseau Internet et hors surcoût éventuel facturé pour le
        chargement de données) depuis la plateforme « Google Play Store et Apple Store ».
        </p>
        <p>
            La version du logiciel de l’application peut être mise à jour de temps à autre pour ajouter
de nouvelles fonctions, de nouveaux services et pour des mesures de sécurité.
L'application mobile 13.Edu est normalement accessible 24h/24h, 7jours/7jours, sauf
évènement occasionnel ou exceptionnel (cas de force majeure ou cas fortuit, opération
de maintenance...)

        </p>
        <p>

En effet, en vue de garantir le bon fonctionnement de l’application et sa sécurité
notamment, 13.Edu est susceptible d'interrompre le service pour des travaux de
maintenance et d’amélioration.
        </p>
        <p>
            En cas d’indisponibilité du service, vous pourrez être notifié toutefois il est convenu que
            notre responsabilité ne pourra être engagée pour les cas d’indisponibilité du service.
            </p>
   </section>
   <section>
    <h3>Article 4 : FONCTIONNALITES OFFERTES</h3>
    <p>Une fois connecté à votre espace client, vous pourrez consulter vos comptes, produits et
        réaliser certaines opérations et avoir accès aux fonctionnalités offertes par l’application.
        Ci-dessous une liste non exhaustive de ces fonctionnalités :</p>
  PRESENTATION DES FONCTIONNALITES DE 13.Edu
        <br>
        <ol>
            <li>ACCUEIL
                <br>
                Un réseau social éducatif.
                Partager des informations du système éducatif en temps réel.
                Publier des contenus éducatifs et divertissants (Images & Vidéos)</li>
            <li> BIBLIOTHEQUE
                <br>
                ❑ Retrouver des résumés de cours.
                <br>
                ❑ Des sujets & corrigés de devoirs.<br>
                ❑ Des sujets & corrigés d’examens.<br>
                ❑ Des sujets & corrigés de concours
            </li>
            <li>SALLE DE CLASSE
                <br>
                ❑ Une classe virtuelle permettant aux apprenants d’un même niveau de communiquer
                entre eux et de partager leurs connaissances. <br>
                ❑ Un espace d’échanges instructifs entre encadreurs et apprenants.
                 
            </li>
            <li>
                LIVRET SCOLAIRE NUMERIQUE
            <br>
                ❑ Il constitue l’archivage des résultats scolaires de l’apprenant.
                <br>
                ❑ Un enregistrement des notes et moyennes de l’apprenant avec des notifications &
                accès aux parents & encadreurs
                <br>
                ❑ Une conservation numérique des relevés de notes et diplômes de l’apprenant.
                
            </li>
            <li>EMPLOI DU TEMPS NUMERIQUE <br>
                ❑ Un support d’organisation et de gestion du temps pour l’apprenant avec des alarmes
                paramétrables.<br>
                ❑ Un accès pour les parents & encadreurs en vue d’un suivi quotidien simple et efficace
                des tâches de l’apprenant.
               
            </li>
            <li> SIMULATEUR D’ORIENTATION
                <br>
                ❑ Orientation en classe de 2nd A & C.<br>
                ✔ Calcul de la moyenne d’orientation.<br>
                ✔ Conseils sur le choix de la série adaptée au profil de l’apprenant.<br>
                ✔ Suggestions sur les lycées d’excellence auxquels le profil de l’apprenant satisfait les
                critères d’admissibilité.
                <br>
                ❑ Orientation en Post-BAC. <br>
                ✔ Etude du profil de l’apprenant.<br>
                <br> ✔ Suggestions des filières dans les universités publiques auxquelles le profil de
                l’apprenant satisfait les critères d’admissibilité.
                <br> ✔ Suggestions des concours d’entrée dans les grandes écoles d’excellence auxquels le
                profil de l’apprenant satisfait les critères d’admissibilité.

            </li>
            <li>SOUTIEN SCOLAIRE <br>
                ❑ Un espace de publication des offres d’encadrement à domicile pour les professionnels
                du domaine sans intermédiaire et avec un contrat formel
               <br> ❑ Un espace de recrutement d’encadreurs professionnels à domicile tout près de chez
                soi avec une meilleure prestation / prix.
               

            </li>
            <li> SUIVI DES SEANCES D’ENCADREMENT
                <br> 
                ❑ Un fiche numérique d’enregistrement du contenu des séances d’encadrement.
                <br>  ❑ Un outil digital, simple et efficace, pour le suivi des prestations d’encadrement à
                domicile.</li>
        </ol>
    
   </section>

   <section>
    <h3>Article 5 : ACCES A L’ESPACE CLIENT ET AUX FONCTIONNALITES DE L’APPLICATION
        MOBILE TREIZE EDUCATION</h3>
    <p>La sécurité et la confidentialité de nos échanges sont notre priorité. C’est pourquoi l’accès
        à votre espace client n’est possible que par une authentification forte.
        Vous devez vous identifier pour accéder à cet espace dédié et utiliser les fonctionnalités
        offertes par l’application mobile 13.Edu. <br>
        En effet, l’accès au service n’est possible qu’au moyen d’un identifiant utilisateur et un
        mot de passe personnalisé. Il s’agit d’un mot de passe que l’utilisateur défini lui-même
        sur le terminal à la fin du processus d’activation suivant des exigences de complexité de
        sécurité. Il servira ultérieurement à accéder à l’application et à signer les opérations
        d’utilisations .

       </p>
    <p>
        Il n’est pas exclu que pour l’usage de certaines fonctionnalités ou opérations importantes,
        vous ayez besoin de vous authentifier en utilisant des procédés de sécurité
        supplémentaire notamment des codes de vérification, des pass de sécurité etc..., dont les
        modalités d’utilisation seront précisées.
        L’accès à notre application et à nos fonctionnalités sont sécurisées par des dispositifs de
        sécurité personnalisés que vous vous engagez à protéger conformément aux dispositions
        ci-après.
        <br>
        Le mot de passe est strictement confidentiel. C'est votre « clé » d'accès aux services
        d’éducation et le moyen de vous authentifier pour l’utilisation et réaliser certaines
        opérations y compris transactionnelles. <br>
        Vous êtes entièrement responsable de sa confidentialité, de sa conservation et de
        l'utilisation que vous en faites. 
        
    </p>
    <p>
        Vous devez donc le tenir secret et ne le communiquer à quiconque pour ne pas engager
        votre responsabilité. Par sécurité, l'accès aux services est temporairement bloqué après
        trois compositions erronées du mot de passe. <br>
        En cas d'oubli ou de perte, vous pouvez solliciter l’envoi par mail à l’adresse
        <a href="mailto:">
        treizeconsulting@gmail.com </a>d’un lien de réinitialisation vous permettant de modifier
votre mot de passe. <br>
La saisie de votre mot de passe reste par ailleurs nécessaire lors de la réalisation de
certaines opérations y compris transactionnelles. <br>
Par ailleurs, la sécurité et la confidentialité de nos échanges étant notre priorité, votre
mobile sur lequel est installée l’application mobile 13.Edu nous sert aussi à vous
reconnaître (en plus de votre identifiant client et votre mot de passe).

    </p>

    <p>
        En effet, nous vous informons que l’application mobile contient une clé de sécurité
        permettant de faire de votre mobile un terminal de confiance. C’est pourquoi vous devez
        réaliser votre première connexion depuis un mobile qui vous appartient. Vous pouvez
        ensuite bien sûr ajouter d’autres terminaux de confiance.
    </p>
   </section>

   <section>
    <h3>Article 6 : SECURISATION DE VOTRE MOT DE PASSE</h3>
    <p>
        Si vous pensez que quelqu’un connaît ou utilise votre mot de passe sans votre autorisation,
        vous devez nous prévenir sans délai en envoyant un mail à <a href="mailto:">treizeconsulting@gmail.com</a> 
       <br> Nous vous recommandons également de modifier immédiatement votre mot de passe
        depuis votre espace client sécurisé afin de rendre l’ancien inutilisable.
       <br> En cas de vol ou perte de votre mobile, vous pourrez supprimer ce terminal de confiance
        en contactant 13.Edu à l’adresse suivante : <a href="mailto:">treizeconsulting@gmail.com</a> 
        </p>
   
   </section>


   <section>
    <h3> Article 7 : RESPONSABILITES</h3>
    <p>
       
Outre son habituelle obligation de diligence en matière d'exécution d'ordres, 13.Edu
assume une obligation de moyens en ce qui concerne la réception et l'émission des
informations. Elle n'assume aucune responsabilité en ce qui concerne leur transport.

    </p>
    <p>
        13.Edu est étrangère à tout litige susceptible de survenir entre l’utilisateur de
l’application et l'opérateur de service de télécommunication ou le fournisseur d’accès
internet.

    </p>
    <p>

Pour des raisons de conformité et de sécurité, 13.Edu peut refuser d’effectuer des
opérations ou services via l’application mobile et ce sans avoir à motiver sa décision de
refus.

    </p>

    Lorsque 13.Edu refuse d’effectuer une opération ou un service, vous êtes notifié sur votre
    espace client dans l’application mobile.
    <p>
        
    La responsabilité de 13.Edu ne pourra pas être engagée du fait d’un refus de cette
    dernière d’effectuer une ou plusieurs opérations ou services.
   
    </p>
    <p>

    </p>
    <p>
        Sa responsabilité limitée aux dommages directs, ne pourra être recherchée que s'il est
        établi qu'elle a commis une faute lourde.
    </p>
    <p>
        L’émission des ordres, la consultation et la diffusion des informations délivrées par
l’application sont de votre responsabilité exclusive sans que la 13.Edu ne soit inquiétée.
<br>  Il en est de même si un tiers pouvait, par tout moyen technique, intercepter et décoder
les signaux radioélectriques et/ou données échangés entre l'opérateur
télécommunication et/ou le fournisseur d’accès internet et l'Utilisateur.


    </p>
    <p>L’exécution de tels ordres par 13.Edu, à défaut d’opposition expresse de votre part,
        n’engage en rien sa responsabilité, dans la mesure où les ordres proviennent, jusqu’à
        preuve du contraire, de votre terminal. <br>
        Nous ne pouvons être tenus responsables de l'inexécution de nos obligations lorsque celleci résulte d'un cas de force majeure, notamment en cas d'interruption du service liée au
        transport des informations ou défaillance de votre terminal.
        </p>
    <p>
        En revanche, 13.Edu n’est pas responsable d’une conséquence d’un défaut de sécurité
        (matériel ou logiciel) du terminal de connexion (smartphone, ordinateur, tablette, …)
        utilisé par l’Abonné.
    </p>
    <p>
        Le Client est responsable de toutes les conséquences qui résulteraient d'une erreur de
        transmission ou de manipulation de sa part.
    </p>
    <p>
        Les informations non contractuel fournies sur l’application mobile 13.Edu ne présentent
        qu’un caractère informatif et ne constituent pas un engagement de la part de Treize
        Consulting.
    </p>
    <p>
        En outre, nous utilisons au mieux les connaissances techniques actuelles pour vous
permettre d’accéder à l’application dans de bonnes conditions et pour préserver la
confidentialité et la sécurité de votre espace client. Cependant, nous ne sommes pas
responsables :
- Du transport des données
- De la qualité et de la disponibilité des réseaux de télécommunication
- Des interruptions de services dues à l’actualisation des informations nécessaires au bon
fonctionnement du service ou à tout acte de maintenance
- Des dommages directs et/ou indirects en cas de présence de virus sur votre mobile ou
causés par une défaillance de votre matériel
- Et, de manière générale, de tout dysfonctionnement ou erreur dont vous êtes
responsable. <br>
Pour des raisons de sécurité nous pouvons être amenés à suspendre différents services.

    </p>
   </section>



   <section>
    <h3> Article 8 : SECRET PROFESSIONNEL</h3>
    <p>
       
        13.Edu est tenue au secret professionnel. Obligation est faite à son personnel de ne pas
        révéler les informations confidentielles auxquelles il pourrait avoir accès. 
        <br> Cependant lorsque la loi le prévoit, le secret professionnel ne peut être opposé aux personnes,
        autorités ou organismes visés par cette dernière.
    </p>
  
   </section>

   <section>
    <h3>Article 9 : CONFIDENTIALITE</h3>
    <p>  Les documents, informations et renseignements fournis par les parties sont confidentiels.
        Ils ne peuvent être utilisés par elles que pour des besoins du contrat et ne peuvent faire
        l’objet d’aucune divulgation à des tiers, sauf si la divulgation est nécessaire en raison
        d’obligation légales ou règlementaires échappant au contrôle des parties.
        </p>
    
   </section>

   <section>
    <h3>Article 10 : ROPRIETE INTELLECTUELLE</h3>
    <p> Toutes les informations disponibles sur le site relèvent de la propriété de l’éditeur et sont
        protégés par le droit de la propriété intellectuelle et plus particulièrement le droit
        d’auteur.
       
    </p>
    <p> Les informations figurant sur le site sont uniquement disponibles à des fins de consultation
        par les utilisateurs, à défaut d’un accord préalable et exprès.
       </p>
    <p>
        Toute utilisation totale ou partielle du site ou de son contenu, par quelque procédé que
        ce soit ou sur quelque support que ce soit, à des fins commerciales ou publicitaires, est
        interdite et engage la responsabilité de l’utilisateur.
  
    </p>
  
    
   </section>

   <section>
    <h3> Article 11 : CAS DE FORCE MAJEURE</h3>
    <p>
       
        13.Edu ne pourrait être tenu pour responsable de tout cas de force majeure indépendant
        de sa volonté, rendant impossible l’exécution de ses obligations, soit partiellement, soit
        en totalité.
    </p>
    <p>
        Les forces majeures suspendent l’exécution des présentes CGU. La force majeure est
entendue dans le cadre de l’exécution du présent contrat comme
Tout événement à la fois imprévisible, insurmontable et indépendant de la volonté des
parties. En cas de suspension dû au cas de force majeure, le client ne recevra aucune
indemnisation. En revanche, il sera remis à l’état initial dans lequel il se trouvait.

    </p>
    <p>

La partie victime de force majeure doit en aviser l’autre partie dans un délai de trente
(30) jours à compter de la survenance de la force majeure. 
    </p>
   </section>

   <section>
    <h3>Article 12 : DUREE MODIFICATION ET RESILIATION</h3>
    <p>
        Les présentes CGU lient les parties à compter de leur acceptation par le client et sont
        conclues pour une durée indéterminée.  <br>
        13.Edu pourra modifier à tout moment les CGU. En cas de modification, le client sera
        informé au moins quinze (15) jours calendaires avant l’entrée en vigueur des nouvelles
        CGU.</p>

        <p>

        </p>
        Toutefois, toutes les modifications qui seront le cas échant, apportées aux stipulations du
        contrat doivent obligatoirement être matérialisées par voie d’avenant au contrat et
        signée par les parties.
            <p>


En continuant à utiliser le service, le client sera considéré comme ayant accepté sans
réserve et expressément les modifications des CGU. Toute notification de refus par le
client des modifications apportées aux CGU entrainera de plein droit la réalisation du
service. 
            </p>    
    </section>

   <section>

    <h3>Article 13 : SANCTIONS</h3>
    <p>
        Tout usage abusif, criminel, délictuel ou frauduleux du service, ainsi que toute fausse
        déclaration est passible des sanctions prévues par la loi.
       </p>
        <p> Toutes fausses déclarations ou usage abusif du service peut également entrainer la perte
            du bénéfice des dispositions contractuelles. </p>
   </section>

   <section>
    <h3> Article 14 : REGLEMENT DES LITIGES – ATTRIBUTION DE JURIDICTION</h3>
    <p>
       
Tout litige né de l’interprétation des présentes, et tout litige né de l’exécution des
présentes devra, au préalable, faire l’objet d’un règlement à l’amiable entre les parties.

    </p>
    <p>
        A défaut de règlement à l’amiable dans un délai de trente (30) jours, à compter de la
réception par l’une des partie, de la demande écrite d’un règlement à l’amiable,
émanant de l’autre partie, le litige sera soumis à la compétence exclusive tu tribunal du
commerce d’Abidjan.
    </p>
    <p>
        La loi applicable au présent contrat est la loi Ivoirienne.
        Pour l’exécution des présentes et leurs suites, il est fait élection de domicile, pour chacune
        des parties, en son siège sociale pour 13.Edu et à son domicile indiqué lors de son
        inscription au service pour le client. </p>
   </section>

    
</div>



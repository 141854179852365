<div
  class="banner-innerpage"
  style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)"
>
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-lg-9 col-md-6 align-self-center text-center">
        <h1 class="title">Detail de la publications</h1>
       <!--   <h5 class="subtitle op-5">
          We are Small Team of Creative People working together
        </h5>-->
      </div>
      <!-- Column -->
    </div>
  </div>
</div>

<!-- Row  -->
<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <!-- Blog  -->

    
        <span  *ngxPermissionsOnly="['Modifier-Accueil', 'Supprimer-Accueil']" (click)="editPost()">
          <button type="button" class="btn bg-info mt-4">Modifier la publication</button>
        </span>


      <div class="mini-spacer">
        <img [src]="blogDetail?.image" alt="wrapkit" class="img-fluid" />
        <div
          class="d-flex align-items-center text-uppercase m-t-40 font-13 font-medium"
        >
       <!--   <a routerLink="" class="link">By John Deo</a> -->
          <div class="ml-auto">
            <a routerLink="" class="link">{{ blogDetail?.blogDate | date: 'MM/dd/yyyy'}} </a>
          </div>
        </div>
        <h2 class="title font-light"> 
          {{ blogDetail?.titre }}
        </h2>
        <p class="m-t-30 m-b-30">
          {{ blogDetail?.sousTitre }}
        </p>
        <p class="m-t-30 m-b-30">
          {{ blogDetail?.descrition }}
        </p>
  <!--
        <div class="m-t-30">
          <h6 class="card-title font-medium">Share:</h6>
          <div class="mt-2">
            <button type="button" class="btn bg-facebook btn-rounded mr-3">
              <i class="fa fa-facebook"></i> Facebook
            </button>
            <button type="button" class="btn bg-twitter btn-rounded">
              <i class="fa fa-twitter"></i> Twitter
            </button>
          </div>
        </div>-->
      </div>
    </div>
  </div>
  <!-- Row  -->
</div>

import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { HttpService } from 'src/app/api/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Niveaux } from '../model/model';


@Component({
  selector: 'app-orientation',
  templateUrl: './orientation.component.html',
  styleUrls: ['./orientation.component.css']
})
export class OrientationComponent implements OnInit {
 
  constructor(private httpService : HttpService, private spinner: NgxSpinnerService) { }
  niveaux: Niveaux[] =[] ;
  moyenne : number =0;
  ngOnInit(): void {
   let  data ={
      "isSimpleLoading":false,
      "data":{
          "code": "3IEME"
      }  
    }
    this.spinner.show();
    this.httpService.get('niveauEtude', data).subscribe(
      (res: any) => {
      if(res &&  res.hasError==false && res['items'] && res.items[0].datasMatiere){
        this.niveaux = res.items[0].datasMatiere;
        this.niveaux .forEach(n => {
          n.ma =0;
          n.mex =0;
        });
      }else
      {
        this.niveaux =[];
      }
     this.spinner.hide();
   })
  }

  calculer (){
    let total = 0;
    let totalCoef = 0;
    this.niveaux .forEach(n => {
      total += (n.ma +n.mex)*n.coeficient;
      totalCoef  += n.coeficient*2;
    });
    let moy : number =total/totalCoef;
    this.moyenne  = parseFloat(moy.toFixed(2)) ;
    console.log('this.niveaux',this.niveaux);
  }

}

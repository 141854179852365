
<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
          <h1 class="title">CONTACTEZ-NOUS</h1>
          <h6 class="subtitle op-8">
            Pour plus d'informations , veuillez nous contacter
          </h6>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>
  
  <div class="contact3 spacer">
    <div class="row">
      <div class="col-md-12 no-trans">
        <div class="container">
            <div class="contact-box m-l-30">
                <h1 class="title font-light m-t-10 color-orange ">Liste des visiteurs</h1>
                <div class="mb-2">
                    <button class="btn btn-primary mx-1" (click)="exportToCSV()">CSV export</button>
                  </div>
                <ngx-table
                #table
                [configuration]="configuration"
                [data]="data"
                [columns]="columns"
                [pagination]="pagination"
                (event)="eventEmitted($event)"
                [detailsTemplate]="detailsTemplate"
              >
              </ngx-table>
              <ng-template #detailsTemplate let-row>
                <div>
                  <h2 class="color-green">Message</h2>
                  {{ row.message }} 
                </div>
              </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>





import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { Publication } from '../../blog/blog-type';
import { HttpService  } from '../../../api/http.service';
import { Observable, ReplaySubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Blog } from '../../blog/blog-type';
import { FormsModule, NgForm } from '@angular/forms';
import { ServiceblogService } from '../../blog/blog-service.service';
interface Food {
  value: string;
  viewValue: string;
}

interface Car {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-new-encadrement',
  templateUrl: './new-encadrement.component.html',
  styleUrls: ['./new-encadrement.component.css']
})
export class NewEncadrementComponent implements OnInit {

  img: any = '';
  currentUser: any = '';
  
  b: Blog = new Blog();
  pub :Publication =new Publication();
  @ViewChild('content', { static: false }) private content: any;

  
  selectedValue: string = '';
  matieres: any;
  offres: any;
;
  selectedCar: string = '';;

  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'},
  ];

  cars: Car[] = [
    {value: 'volvo', viewValue: 'Volvo'},
    {value: 'saab', viewValue: 'Saab'},
    {value: 'mercedes', viewValue: 'Mercedes'},
  ];
  periodes = [
    {code: 'Mensuelle', libelle: 'Mensuel'},
    {code: 'Trimestrielle', libelle:'Trimestrielle'},
    {code: 'Semestrielle', libelle: 'Semestrielle'},
    {code: 'Annuelle', libelle: 'Semestriel'},
  ];

  constructor(public service: ServiceblogService, 
    public httpService : HttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public router: Router, private modalService: NgbModal) {
    this.service.isLoggedIn();
  }
  ngOnInit(): void {
   let user :any  = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(user) ;
    this.spinner.show();

    this.httpService.getMatiere().subscribe(
      (res: any) => {
      if(res.hasError ==false) this.matieres = res.items;
      else this.offres= [];
      this.spinner.hide();
   });


    this.httpService.getOffre().subscribe(
      (res: any) => {
      if(res.hasError ==false) this.offres = res.items;
      else this.offres = [];
     
     this.spinner.hide();
   })
    
  }




  submit(data: NgForm) {
    this.spinner.show();
    let save  : any  = data.value;
     save.matriculeFk =  this.currentUser.matricule;
     var d =
      {
        "datas":[save]  
      }
    this.httpService.create('demandeEncadrement', d )
    .subscribe((res: any) =>  {
      if(res.hasError ==false) {
        this.toastr.success('Succes', 'Opération effectué avec succès');
        this.router.navigate(['/offre']);
      }
        else this.toastr.error('Echec', 'Merci de réessayer');
        this.spinner.hide();
    })
  }



}

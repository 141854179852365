<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-md-6 align-self-center text-center no-trans">
        <h1 class="title">CONTACTEZ-NOUS</h1>
        <h6 class="subtitle op-8">
          Pour plus d'informations , veuillez nous contacter
        </h6>
      </div>
      <!-- Column -->
    </div>
  </div>
</div>

<div class="contact3 spacer">
  <div class="row">
    <div class="col-md-12 no-trans">
      <div class="container">
        <div class="row m-0">
          <div class="col-lg-7 no-trans">
            <div class="card-shadow m-t-20" data-aos="flip-left" data-aos-duration="1200">
              <img [src]="img" alt="wrapkit" class="img-responsive" />
            </div>
          </div>
          <div class="col-lg-5 no-trans">
            <div class="contact-box m-l-30">
            <!--  <h1 class="title font-light m-t-10">Contactez-nous</h1>-->


              <form #contact="ngForm" (ngSubmit)="submit(contact)" data-aos="fade-left"
                data-aos-duration="1200">
                <div class="row">
                  <div class="col-lg-12 no-trans">
                    <div class="form-group m-t-10">
                      <input type="text" required class="form-control" placeholder="Nom"
                        [class.is-invalid]="nom.invalid && nom.touched" name="nom" #nom="ngModel" ngModel />
                    </div>
                  </div>
                  <div class="col-lg-12 no-trans">
                    <div class="form-group m-t-10">
                      <input type="text" required class="form-control" placeholder="Prenoms"
                        [class.is-invalid]="prenons.invalid && prenons.touched" name="prenons" #prenons="ngModel" ngModel />
                    </div>
                  </div>
                  <div class="col-lg-12 no-trans">
                    <div class="form-group m-t-10">
                      <input type="email" email required class="form-control" placeholder="Addresse email"
                        [class.is-invalid]="email.invalid && email.touched" name="email" #email="ngModel" ngModel />
                    </div>
                  </div>
                  <div class="col-lg-12 no-trans">
                    <div class="form-group m-t-10">
                      <input type="number" required class="form-control" placeholder="Numero de téléphone"
                        [class.is-invalid]="telephone1.invalid && telephone1.touched" name="telephone1" #telephone1="ngModel" ngModel />
                    </div>
                  </div>
                  <div class="col-lg-12 no-trans">
                    <div class="form-group m-t-10">
                      <input type="number"  class="form-control" placeholder="Autre numero de téléphone"
                        [class.is-invalid]="telephone2.invalid && telephone2.touched" name="telephone2" #telephone2="ngModel" ngModel />
                    </div>
                  </div>
                  <div class="col-lg-12 no-trans">
                    <div class="form-group m-t-10">
                      <textarea required class="form-control" placeholder="Message" rows="3"
                        [class.is-invalid]="message.invalid && message.touched" name="message" #message="ngModel"
                        ngModel></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12 no-trans">
                    <button type="submit" class="btn btn-info-gradiant btn-md m-t-20 btn-arrow"
                      [disabled]="contact.invalid">
                      <span> SOUMETTRE <i class="ti-arrow-right"></i></span>
                    </button>
                  </div>
                </div>
              </form>


            


            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1619902.0054433027!2d-122.68851282163044!3d37.534535608111824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan+Francisco%2C+CA%2C+USA!5e0!3m2!1sen!2sin!4v1507725785789"
  width="100%" height="450" style="border: 0"></iframe>-->
<!-- ============================================================== -->
<!-- Call to action -->
<!-- ============================================================== -->
<!--<div class="spacer">
  <div class="container">
    <div class="row justify-content-center m-b-30">
      <div class="col-md-7 text-center no-trans">
        <h2 class="title">
          {{ title }}
        </h2>
        <h6 class="subtitle">
          {{ subTitle }}
        </h6>
        <a class="btn btn-info-gradiant btn-md btn-arrow m-t-20" data-toggle="collapse" routerLink="#">
          <span>
            Buy Wrapkit Angular Blog
            <i class="ti-arrow-right"></i>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>-->


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Vous allez transmettre ses informations</h4>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="fclick(contact)">
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Nom :</label>
        <label>{{ con.nom }}</label>
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Premoms :</label>
        <label>{{ con.prenons }}</label>
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Addresse email :</label>
        <label>{{ con.email }}</label>
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Numero de téléphone :</label>
        <label>{{ con.telephone1 }}</label>
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Numero de téléphone :</label>
        <label>{{ con.telephone2 }}</label>
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Message :</label>
        <label>{{ con.message }}</label>
      </div>
      <button type="submit" class="btn btn-info-gradiant btn-block">ENVOYER</button>
    </form>
  </div>
</ng-template>


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpService  } from '../../api/http.service';
import { Publication } from './blog-type';
@Injectable({
  providedIn: 'root'
})
export class ServiceblogService {

  Blogs: any[] = [];
  Publications: any[] = [];
  Documents: any[] = [];
  Offres: any[] = [];
  
  pagination = {
    count : 0,
    pageSize :6,
    page :  0
  }
  
  action: any[] = [];
  loginStatusService = false;

  data  =
  {
    "user":"1",
    "isSimpleLoading":false,
    "data":{}  
  };
   httpOptions  = {
    headers: new HttpHeaders({
      'Content-Type' : 'application/json; charset=utf-8',
      'Accept'       : 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Transfer-Encoding' : 'chunked'
    })
  };
  constructor(public http: HttpClient,
    public httpService : HttpService
    ) {
  }

  public getPublication(): Observable<any> {
    return   this.httpService.getPublication();
  }

  
  public getBlog(): Observable<any> {
    this.http.get<any>('https://api.npoint.io/aba0bbc3361bfc50d277').subscribe((d: any) => (this.Blogs = d));
    return this.http.get<any>('https://api.npoint.io/aba0bbc3361bfc50d277');
 
  }


  public addPost(bl: any) {
    this.Blogs.splice(0, 0, bl);
  }

  public deletePost(id: number) {
    this.Blogs = this.Blogs.filter(b => b.id !== id);
  }

  isLoggedIn() {
    if (localStorage.getItem('currentUser') !== null) {
      this.loginStatusService = true;
    }
  }
}

<div id="main-wrapper">
  <app-banner></app-banner>

  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- <app-banner-content></app-banner-content>       -->

      <div class="">
        <div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Back to top -->
    <!-- ============================================================== -->
    <a class="bt-top btn btn-circle btn-lg btn-info" href="#top"
      ><i class="ti-arrow-up"></i
    ></a>
  </div>

  <app-footer></app-footer>
</div>

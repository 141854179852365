import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';



// used to create fake backend
import { fakeBackendProvider } from '../app/jwt/_helpers/fake-backend';
import { JwtInterceptor } from '../app/jwt/_helpers/jwt.interceptor';
import { ErrorInterceptor } from '../app/jwt/_helpers/error.interceptor';

import { BlogComponent } from './pages/blog/blog.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';

import { NewPostComponent } from './pages/blog/new-post/new-post.component';
import { EditPostComponent } from './pages/blog/edit-post/edit-post.component';
import { ServiceblogService } from './pages/blog/blog-service.service';
import { RelayOnComponent } from './pages/about/About-Components/relay-on/relay-on.component';
import { AchivementComponent } from './pages/about/About-Components/achivement/achivement.component';
import { TeamComponent } from './pages/about/About-Components/team/team.component';
import { TopContentComponent } from './pages/about/About-Components/top-content/top-content.component';

import { FullComponent } from './layout/full/full.component';
import { LoginComponent } from './pages/login/login.component';

import { BannerComponent } from './shared/banner/banner.component';

import { BannerNavigationComponent } from './shared/banner-navigation/banner-navigation.component';
import { FooterComponent } from './shared/footer/footer.component';

import { AccordionComponent } from './pages/ui-elements/accordion/accordion.component';
import { AlertComponent } from './pages/ui-elements/alert/alert.component';
import { CarouselComponent } from './pages/ui-elements/carousel/carousel.component';
import { DropdownComponent } from './pages/ui-elements/dropdown/dropdown.component';
import { ModalComponent } from './pages/ui-elements/modal/modal.component';
import { PaginationComponent } from './pages/ui-elements/pagination/pagination.component';
import { PopovertooltipComponent } from './pages/ui-elements/popovertooltip/popovertooltip.component';
import { ProgressbarComponent } from './pages/ui-elements/progressbar/progressbar.component';
import { RatingComponent } from './pages/ui-elements/rating/rating.component';
import { TabComponent } from './pages/ui-elements/tab/tab.component';
import { TimepickerComponent } from './pages/ui-elements/timepicker/timepicker.component';
import { ButtonComponent } from './pages/ui-elements/button/button.component';
import { CardComponent } from './pages/ui-elements/card/card.component';
import { ContactComponent } from './pages/contact/contact.component';

import { InscriptionComponent } from './pages/inscription/inscription.component';
import { ActionComponent } from './pages/admin/action/action.component';


import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Import library module
import { NgxSpinnerModule , NgxSpinnerService} from "ngx-spinner";
import { TableModule } from 'ngx-easy-table';
import { NgxPermissionsModule } from 'ngx-permissions';

import { CompanyService } from "./pages/admin/company.service";
import { ContactAdminComponent } from './pages/admin/contact-admin/contact-admin.component';
import { UserComponent } from './pages/admin/user/user.component';

import { ToastrModule } from 'ngx-toastr';
import { BibliothequeComponent } from './pages/bibliotheque/bibliotheque.component';
import { ParcoursComponent } from './pages/parcours/parcours.component';
import { OrientationComponent } from './pages/orientation/orientation.component';
import { OffreComponent } from './pages/offre/offre.component';
import { EncadrementComponent } from './pages/encadrement/encadrement.component';
import { InformationComponent } from './pages/information/information.component';
import { NewBibliothequeComponent } from './pages/bibliotheque/new-bibliotheque/new-bibliotheque.component';
import { DetailBibliothequeComponent } from './pages/bibliotheque/detail-bibliotheque/detail-bibliotheque.component';
import { EditBibliothequeComponent } from './pages/bibliotheque/edit-bibliotheque/edit-bibliotheque.component';
import { MatiereComponent } from './pages/admin/matiere/matiere.component';
import { ClasseComponent } from './pages/admin/classe/classe.component';
import { ConcoursComponent } from './pages/admin/concours/concours.component';
import { EcoleComponent } from './pages/admin/ecole/ecole.component';
import { ArchwizardModule } from 'angular-archwizard';
import { AdimOffreComponent } from './pages/admin/adim-offre/adim-offre.component';
import { AdimEncadrementComponent } from './pages/admin/adim-encadrement/adim-encadrement.component';
import { EditOffreComponent } from './pages/offre/edit-offre/edit-offre.component';
import { NewOffreComponent } from './pages/offre/new-offre/new-offre.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NewEncadrementComponent } from './pages/encadrement/new-encadrement/new-encadrement.component';
import { EditEncadrementComponent } from './pages/encadrement/edit-encadrement/edit-encadrement.component';
import { PolitiqueComponent } from './pages/politique/politique.component';
import { ConditionComponent } from './pages/condition/condition.component';

@NgModule({
  declarations: [
    AppComponent,
    BlogComponent,
    AboutComponent,
    BlogDetailComponent,
    LoginComponent,
    NewPostComponent,
    EditPostComponent,
    RelayOnComponent,
    AchivementComponent,
    TeamComponent,
    TopContentComponent,
    FullComponent,
    BannerComponent,
    BannerNavigationComponent,
    FooterComponent,
    AccordionComponent,
    AlertComponent,
    CarouselComponent,
    DropdownComponent,
    ModalComponent,
    PaginationComponent,
    PopovertooltipComponent,
    ProgressbarComponent,
    RatingComponent,
    TabComponent,
    TimepickerComponent,
    ButtonComponent,
    CardComponent,
    ContactComponent,
    InscriptionComponent,
    ActionComponent,
    ContactAdminComponent,
    UserComponent,
    BibliothequeComponent,
    ParcoursComponent,
    OrientationComponent,
    OffreComponent,
    EncadrementComponent,
    InformationComponent,
    NewBibliothequeComponent,
    DetailBibliothequeComponent,
    EditBibliothequeComponent,
    MatiereComponent,
    ClasseComponent,
    ConcoursComponent,
    EcoleComponent,
    AdimOffreComponent,
    AdimEncadrementComponent,
    EditOffreComponent,
    NewOffreComponent,
    NewEncadrementComponent,
    EditEncadrementComponent,
    PolitiqueComponent,
    ConditionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    TableModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 100000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    ArchwizardModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    
  ],
  providers: [
    ServiceblogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
    NgxSpinnerService,
    CompanyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


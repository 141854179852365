<div
  class="banner-innerpage"
  style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)"
>
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-lg-9 col-md-6 align-self-center text-center">
        <h1 class="title">Detail du document</h1>
      </div>
      <!-- Column -->
    </div>
  </div>
</div>

<!-- Row  -->
<div class="container">
  <span  *ngxPermissionsOnly="['Modifier-Accueil', 'Supprimer-Accueil']" (click)="editPost()">
    <button type="button" class="btn bg-info mt-4">Modifier le document</button>
  </span>
  <div class="row justify-content-center">

          <div class="mini-spacer">
                <img [src]="bibDetail?.image" alt="document" class="img-fluid" />
                <h2 class="title font-light"> 
                  {{ bibDetail?.typeDocumentFk }}
                </h2>
                <p class="m-t-30 m-b-30">
                  {{ bibDetail?.libelle }}
                </p>
              <div class="d-flex align-items-center text-uppercase m-t-40 font-13 font-medium col-md-12 ">
                
                <p class="m-t-30 m-b-30">
                  {{ bibDetail?.description }}
                </p>
              </div>
          </div>
  </div>
</div>

    <!--     <span  *ngxPermissionsOnly="['Modifier-Accueil', 'Supprimer-Accueil']" (click)="editPost()">
          <button type="button" class="btn bg-info mt-4">Modifier le document</button>
        </span>


      <div class="mini-spacer">
          <div class="col-md-2 col-lg-2">
             <img [src]="bibDetail?.image" alt="document" class="img-fluid" />
        </div> 
        <div class="col-md-6 col-lg-8">
          <h2 class="title font-light"> 
            {{ bibDetail?.typeDocumentFk }}
          </h2>
         
        </div> 
    </div>
  </div>
</div>
</div> 
<div class="d-flex align-items-center text-uppercase m-t-40 font-13 font-medium col-md-12 ">
  <p class="m-t-30 m-b-30">
    {{ bibDetail?.description }}
  </p>
</div>-->
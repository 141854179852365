import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
 //import { environment } from '../../../environments/environment';

import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject('');
    public currentUser: Observable<any>;
    currentProfil : any ={};

    httpOptions  = {
        headers: new HttpHeaders({
          'Content-Type' : 'application/json; charset=utf-8',
          'Accept'       : 'application/json',
          'Access-Control-Allow-Origin' : '*',
          'Transfer-Encoding' : 'chunked'
        })
      };
    
    constructor(private http: HttpClient,
        private ps: NgxPermissionsService,
        private rs: NgxRolesService) {
        if (localStorage.getItem('currentUser') !== null)
            this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));

        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    loginx(username: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}/utilisateur/login`, { username, password })
            .pipe(map(user => {      
            localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);              
                return user;
            }));
    }
    login(username: string, password: string) {
      var data=  {
             "data": {
                "login": username,
                "password": password
            }
        }

        return this.http.post<any>(environment.apiUrl+'utilisateur/login', data, this.httpOptions)
        .pipe(map(user => {      
             
                
             if( user && user['items'][0]){
                localStorage.setItem('currentUser', JSON.stringify(user['items'][0]));
                this.currentUserSubject.next(user['items'][0]);
                
                
                this.currentProfil['code'] = user['items'][0]['codeProfil'];
                this.currentProfil['libelle'] = user['items'][0]?.profilLibelle;
                this.currentProfil['datasActionFonctionnalite'] = user['items'][0]?.datasActionFonctionnalite;

                localStorage.removeItem('currentProfil');
                localStorage.setItem('currentProfil', JSON.stringify(this.currentProfil));
               
                let permissions =this.currentProfil['datasActionFonctionnalite'].map( (item :any) => item.codeAction+'-'+item.codeFonctionnalite) 
                if(permissions) this.ps.loadPermissions(permissions);
                console.log('permissions',permissions);
              }
            return user;
        }));
    }


    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentProfil');
        this.currentUserSubject.next(null);
    }


}
<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
          <h1 class="title">Nouvelle demande d'encadrement</h1>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>
  
  <div class="container">
    <div class="row justify-content-center pt-5">
      <div class="col-md-8 no-trans">
        <form #post="ngForm" (ngSubmit)="submit(post)">
          
                
          <div class="form-group">
            <label class="text-dark font-medium mr-3">Offre :</label>
              <select   [class.is-invalid]="name.invalid && name.touched" required class="form-control"  [class.is-invalid]="name.invalid && name.touched" name="idOffre" #name="ngModel" ngModel>
                <option value="">--Choisiir une offre--</option>
                <option *ngFor="let o of offres; let index = index; let isFirst = first; let isOdd = odd;" [value]="o.id">
                      {{' Encadrement en '+ o.matiereLibelle  + ' Niveau: '+ o.classeLibelle + ' à ' + o.coutFormation +' FCFA' }}</option> 
              </select>
          </div>
          <div class="form-group">
            <label class="text-dark font-medium mr-3">Classe :</label>
              <select   [class.is-invalid]="name.invalid && name.touched" required class="form-control"  [class.is-invalid]="name.invalid && name.touched" name="idOffre" #name="ngModel" ngModel>
                <option value="">--Choisir une matière--</option>
                <option *ngFor="let m of matieres; let index = index; let isFirst = first; let isOdd = odd;" [value]="m.code">
                      {{ m.code  + '-'+ m.libelle  }}</option> 
              </select>
          </div>
          
          <div class="form-group"> 
            <label class="text-dark font-medium mr-3">Description de la demande:</label>
            <textarea required class="form-control" rows="5" placeholder="Description"
              [class.is-invalid]="textBody.invalid && textBody.touched" name="textBody" #textBody="ngModel" ngModel>
            </textarea>
          </div>
            <button type="submit" class="btn btn-info-gradiant btn-block bg-bleu " [disabled]="post.invalid">
              Enregistrer 
            </button>
        </form>
        <hr class="op-5" />
      </div>
    </div>
  </div>


  
import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpService  } from './api/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject('');
  private currentProfilSubject: BehaviorSubject<any> = new BehaviorSubject('');
  currentProfil : any ;
  currentProfilStr : any ;


  title = 'blogApp';
  constructor(private httpService :HttpService, 
    private spinner: NgxSpinnerService,
    private ps: NgxPermissionsService,
    private rs: NgxRolesService) {}

  ngOnInit() {
  /*  let data =  {
     "user":"1",
      "isSimpleLoading":false,
      "data":{
          "code": "VISITEUR"
      }  
    }; 
    this.currentProfilStr =  localStorage.getItem('currentProfil');
    this.currentProfil = (this.currentProfilStr && this.currentProfilStr.length>1 )?  JSON.parse(this.currentProfilStr) : undefined;
    if(!this.currentProfil ){
      this.spinner.show();
      this.httpService.get('profil', data).subscribe((profil: any) => {
      this.currentProfil = profil['items'][0];
        if( this.currentProfil &&  this.currentProfil['datasActionFonctionnalite']){
          let permissions =this.currentProfil['datasActionFonctionnalite'].map( (item :any) => item.codeAction+'-'+item.codeFonctionnalite);
          localStorage.setItem('currentProfil', JSON.stringify(this.currentProfil));
          this.currentProfilSubject.next(this.currentProfil); 
          if(permissions) this.ps.loadPermissions(permissions);
          console.log('permissions',permissions);
        }
      
        this.spinner.hide();
     }
      );
    }*/
    
}

}

<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
        <h1 class="title">Nouveau Post</h1>
      </div>
      <!-- Column -->
    </div>
  </div>
</div>

<div class="container">
  <div class="row justify-content-center pt-5">
    <div class="col-md-8 no-trans">
      <form #post="ngForm" (ngSubmit)="submit(post)">
        <div class="form-group">
          <label class="text-dark font-medium mr-3">Titre :</label>
          <input type="text" required class="form-control" placeholder="Titre du post"
            [class.is-invalid]="heading.invalid && heading.touched" name="heading" #heading="ngModel" ngModel />
        </div>
        <div class="form-group">
          <label class="text-dark font-medium mr-3">Sous titre :</label>
          <input type="text" required class="form-control" placeholder="Sous titre du post"
            [class.is-invalid]="subheading.invalid && subheading.touched" name="subheading" #subheading="ngModel"
            ngModel />
        </div>
        <div class="form-group">
          <label class="text-dark font-medium mr-3">Image :</label>
          <input type="file" class="form-control form-control-file" (change)="onFileSelected($event)" #filevar />
        </div>
        <div class="form-group"> 
          <label class="text-dark font-medium mr-3">Description :</label>
          <textarea required class="form-control" rows="5" placeholder="Coprs du post"
            [class.is-invalid]="textBody.invalid && textBody.touched" name="textBody" #textBody="ngModel" ngModel>
          </textarea>
        </div>
        <button type="submit" class="btn btn-info-gradiant btn-block" [disabled]="post.invalid">
          Enregistrer Post
        </button>
      </form>
      <hr class="op-5" />
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Post</h4>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="fclick()">
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Titre :</label>
        <label>{{ b.heading }}</label>
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Sous titre :</label>
        <label>{{ b.subHeading }}</label>
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3"></label>
        <img [src]="b.image" class="img-fluid" />
      </div>
      <div class="form-group">
        <label class="text-dark font-medium mr-3">Description :</label>
        <label>{{ b.blogDetail }}</label>
      </div>
      <button type="submit" class="btn btn-info-gradiant btn-block">OK</button>
    </form>
  </div>
</ng-template>
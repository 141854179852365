<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
          <h1 class="title">Orientation </h1>
          <h6 class="subtitle op-8">
            Votre simulateur de moyenne et d'orientation
          </h6>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>

  <div class="container spacer">
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-md-7  no-trans bg-moy">
        <h2 class="title">Moyenne d'orientation</h2>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Matière</th>
              <th scope="col">MA/20</th>
              <th scope="col">Note BEPC</th>
              <th scope="col">Coeficient</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let n of niveaux; let i = index; let isFirst = first; let isOdd = odd;">
                <th style="width: 40px;" scope="row">{{ i + 1 }}</th>
                <td>
                  {{ n.matiereLibelle }}
                </td>
                  <td class="text-right">  
                      <input type="number" required class="form-control text-right" placeholder="MA" [(ngModel)] ="n.ma" />
                  </td>
                  <td class="text-right"> 
                   <input type="number" required class="form-control text-right" placeholder="BEPC/20" [(ngModel)] ="n.mex" />
                  </td>
                 <td class="text-center">{{ n.coeficient | number }}</td>
              </tr>
            <tr>
              
               <td style="padding-left: 0;" colspan="2" >   
                <button style = "width: 182px " (click)="calculer()"  class="btn btn-primary"> Calculer</button>
               </td>
               <td>  
               </td>
               <td style="padding-right: 0;" colspan="2">   
                <input type="number" required class="form-control st-moy text-right" placeholder="BEPC/20" [(ngModel)] ="moyenne"  />
               </td>
            </tr>
          
          </tbody>
        </table>
        
      </div>
    </div>
</div>



<div class="row wrap-feature-22">
  <!-- Column -->
  <div class="col-lg-6" data-aos="flip-up" data-aos-duration="1200">
    <img [src]="imagePath" class="rounded img-responsive" alt="wrapkit" />
  </div>
  <!-- Column -->
  <div class="col-lg-6 no-trans">
    <div class="text-box">
      <small class="text-info font-medium">Qui sommes nous?</small>
      <h3 class="font-light">
        Crée depuis 2014
        <span class="text-info">Treize Academy</span> est le programme éducatif initié
        par l'entreprise  <span class="text-info">Treize Consulting</span> 
      </h3>
      <p>
        A travers une methode d'encadrement propre à notre academie ,
         nous intervenons au niveau :
      </p>
      <p>
        Des soutiens scolaire particuliers à domicile et  des conseils d'orientation
      </p>
      <p>
        Des prepas spéciales aux concours Post <span class="text-info">BAC</span> d'entrée dans les universités d'excellence
      </p>
      
    
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
//import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  
  data  =
  {
    "user":"1",
    "isSimpleLoading":false,
    "data":{}  
  };

   httpOptions  = {
    headers: new HttpHeaders({
      'Content-Type' : 'application/json; charset=utf-8',
      'Accept'       : 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Transfer-Encoding' : 'chunked'
    })
  };
  httpsOptions  = {
    headers: new HttpHeaders({
      'Content-Type' : 'application/json; charset=utf-8',
      'Accept'       : 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Headers' : '*',
      'X-Powered-By' : 'Express',
      'ETag' : 'W/"35a-V15oVpwHN0jBGP5cr5uJtRIWeW4"',
      'Connection' : 'keep-alive'

    })
  };

 
  constructor(public http: HttpClient) {
  }

  public create(ressource: string, d : any): Observable<any> {
    return this.http.post<any>(environment.apiUrl+ressource+'/create' ,d, this.httpOptions );
  } 
  public update(ressource: string, d : any): Observable<any> {
    return this.http.post<any>(environment.apiUrl+ressource+'/update' ,d, this.httpOptions );
  }
  public delete(ressource: string,id :any): Observable<any> {
    return this.http.post<any>(environment.apiUrl+ressource+'/delete' ,this.data, this.httpOptions );
  }
  public get(ressource: string, d?: any): Observable<any> {
     if (typeof d !== 'undefined') {
      this.data = d ;
     }
    return this.http.post<any>(environment.apiUrl+ressource+'/getByCriteria' ,this.data, this.httpOptions );
  }
  public fetch(apiUrl: string, body?: any,  headers?: any): Observable<any> {
   return this.http.post<any>(apiUrl ,body, headers );
 }

  public getCities(): Observable<any> {
    console.log('Cities')
    let data = 
    {
      "country": 'ivory coast'
     }
   return this.http.post<any>('https://countriesnow.space/api/v0.1/countries/cities' ,data, this.httpsOptions );
 }


  
  public getAction(): Observable<any> {
    var  d  =
    {
     "user":"1",
     "isSimpleLoading":false,
     "data":{
       code : 'Consulter'
     }  
   };
     return this.get('action' )
   }
   public getPublication(): Observable<any> {return this.get('publication' )}
   public getContact(): Observable<any> {return this.get('contact')}
   public getUser(): Observable<any> {return this.get('utilisateur' )}
   public getEcole(): Observable<any> {return this.get('ecole')}
   public getConcours(): Observable<any> {return this.get('concours')}
   public getMatiere(): Observable<any> {return this.get('matiere')}
   public getClasse(): Observable<any> {return this.get('classe')}
   public getNiveauEtude(): Observable<any> {return this.get('niveauEtude')}
   public getOffre(): Observable<any> {return this.get('offreEncadrement')}
   public getEncadrement(): Observable<any> {return this.get('demandeEncadrement')}
   
 
   

}

<div class="footer4 b-t spacer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 m-b-30">
        <h5 class="m-b-20">Adresse</h5>
        <p> 11 BP 1249 Abj 11</p>
      </div>
      <div class="col-lg-3 col-md-6 m-b-30">
        <h5 class="m-b-20">Téléphone</h5>
        <p> +225 07 09 699 681</p>
      </div>
      <div class="col-lg-3 col-md-6 m-b-30">
        <h5 class="m-b-20">Email</h5>
        <p>treizeconsulting@gmail.com</p>
      </div>
      <div class="col-lg-3 col-md-6">
        <h5 class="m-b-20">Reseaux sociaux</h5>
        <div class="round-social light">
          <a routerLink="#" class="link"><i class="fab fa-facebook-f"></i></a>
          <a routerLink="#" class="link"><i class="fab fa-twitter"></i></a>
          <a routerLink="#" class="link"
            ><i class="fab fa-google-plus-g"></i
          ></a>
          <a routerLink="#" class="link"><i class="fab fa-youtube"></i></a>
          <a routerLink="#" class="link"><i class="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
    <div class="f4-bottom-bar">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex font-14">
            <div class="m-t-10 m-b-10 copyright">
              copyright @flexTech.
            </div>
            <div class="links ml-auto m-t-10 m-b-10">
              <a routerLink="#" class="p-10 p-l-0">Terms of Use</a>
              <a routerLink="#" class="p-10">Legal Disclaimer</a>
              <a routerLink="#" class="p-10">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlogComponent } from './pages/blog/blog.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';
import { NewPostComponent } from './pages/blog/new-post/new-post.component';
import { EditPostComponent } from './pages/blog/edit-post/edit-post.component';

import { FullComponent } from './layout/full/full.component';
import { LoginComponent } from './pages/login/login.component';

import { ContactComponent } from './pages/contact/contact.component';

import { AccordionComponent } from './pages/ui-elements/accordion/accordion.component';
import { AlertComponent } from './pages/ui-elements/alert/alert.component';
import { CarouselComponent } from './pages/ui-elements/carousel/carousel.component';
import { DropdownComponent } from './pages/ui-elements/dropdown/dropdown.component';
import { ModalComponent } from './pages/ui-elements/modal/modal.component';

import { PaginationComponent } from './pages/ui-elements/pagination/pagination.component';
import { PopovertooltipComponent } from './pages/ui-elements/popovertooltip/popovertooltip.component';
import { ProgressbarComponent } from './pages/ui-elements/progressbar/progressbar.component';
import { RatingComponent } from './pages/ui-elements/rating/rating.component';
import { TabComponent } from './pages/ui-elements/tab/tab.component';
import { TimepickerComponent } from './pages/ui-elements/timepicker/timepicker.component';
import { ButtonComponent } from './pages/ui-elements/button/button.component';
import { CardComponent } from './pages/ui-elements/card/card.component';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import { ActionComponent } from './pages/admin/action/action.component';
import { UserComponent } from './pages/admin/user/user.component';
import { ContactAdminComponent } from './pages/admin/contact-admin/contact-admin.component';
import { BibliothequeComponent } from './pages/bibliotheque/bibliotheque.component';
import { InformationComponent } from './pages/information/information.component';
import { ParcoursComponent } from './pages/parcours/parcours.component';
import { OffreComponent } from './pages/offre/offre.component';
import { EncadrementComponent } from './pages/encadrement/encadrement.component';
import { OrientationComponent } from './pages/orientation/orientation.component';
import { EditBibliothequeComponent } from './pages/bibliotheque/edit-bibliotheque/edit-bibliotheque.component';
import { DetailBibliothequeComponent } from './pages/bibliotheque/detail-bibliotheque/detail-bibliotheque.component';
import { ConcoursComponent } from './pages/admin/concours/concours.component';
import { MatiereComponent } from './pages/admin/matiere/matiere.component';
import { EcoleComponent } from './pages/admin/ecole/ecole.component';
import { ClasseComponent } from './pages/admin/classe/classe.component';
import { AdimOffreComponent } from './pages/admin/adim-offre/adim-offre.component';
import { AdimEncadrementComponent } from './pages/admin/adim-encadrement/adim-encadrement.component';
import { NewOffreComponent } from './pages/offre/new-offre/new-offre.component';
import { EditOffreComponent } from './pages/offre/edit-offre/edit-offre.component';
import { NewEncadrementComponent } from './pages/encadrement/new-encadrement/new-encadrement.component';
import { EditEncadrementComponent } from './pages/encadrement/edit-encadrement/edit-encadrement.component';
import { NewBibliothequeComponent } from './pages/bibliotheque/new-bibliotheque/new-bibliotheque.component';
import { PolitiqueComponent } from './pages/politique/politique.component';
import { ConditionComponent } from './pages/condition/condition.component';


const routes: Routes = [
  
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', component: BlogComponent },
      { path: 'blogDetail/:id', component: BlogDetailComponent },
      { path: 'post', component: NewPostComponent },
      { path: 'editPost/:id', component: EditPostComponent },
      { path: 'about', component: AboutComponent },

      { path: 'accordion', component: AccordionComponent },
      { path: 'alert', component: AlertComponent },
      { path: 'carousel', component: CarouselComponent },
      { path: 'dropdown', component: DropdownComponent },
      { path: 'modal', component: ModalComponent },
      { path: 'pagination', component: PaginationComponent },
      { path: 'popovertooltip', component: PopovertooltipComponent },
      { path: 'progress', component: ProgressbarComponent },
      { path: 'rating', component: RatingComponent },
      { path: 'tab', component: TabComponent },
      { path: 'timepicker', component: TimepickerComponent },
      { path: 'button', component: ButtonComponent },
      { path: 'card', component: CardComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'inscription', component: InscriptionComponent },
      { path: 'action', component: ActionComponent },
      { path: 'user', component: UserComponent },
      { path: 'visiteur', component: ContactAdminComponent },

      { path: 'bibliotheque', component: BibliothequeComponent },
      { path: 'newbibliotheque', component: NewBibliothequeComponent },
      { path: 'bibliothequeDetail/:id', component: DetailBibliothequeComponent },
      { path: 'editBibliotheque/:id', component: EditBibliothequeComponent },

      { path: 'information', component: InformationComponent },
      { path: 'parcours', component: ParcoursComponent },

      { path: 'offre', component: OffreComponent },
      { path: 'newOffre', component: NewOffreComponent },
      { path: 'editOffre/:id', component: EditOffreComponent },


      { path: 'encadrement', component: EncadrementComponent },
      { path: 'newEncadrement', component: NewEncadrementComponent },
      { path: 'editEncadrement/:id', component: EditEncadrementComponent },

      { path: 'orientation', component: OrientationComponent },
     
      { path: 'concours', component: ConcoursComponent },
      { path: 'matiere', component: MatiereComponent },
      { path: 'ecole', component: EcoleComponent },
      { path: 'classe', component: ClasseComponent },

      { path: 'adminoffre', component: AdimOffreComponent },
      { path: 'adminencadrement', component: AdimEncadrementComponent },
      { path: 'politique', component: PolitiqueComponent },
      { path: 'condition', component: ConditionComponent },

    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

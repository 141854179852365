

import { FormControl, Validators } from '@angular/forms';

import {
  Component,
  VERSION,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import {
  Columns,
  Config,
  DefaultConfig,
  API,
  APIDefinition
} from 'ngx-easy-table';
import { Subject } from 'rxjs';
import { HttpService  } from '../../api/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-encadrement',
  templateUrl: './encadrement.component.html',
  styleUrls: ['./encadrement.component.css']
})
export class EncadrementComponent implements OnInit {


  currentRate = 5;
  currentRate2 = 2;
  selected = 0;
  hovered = 0;
  readonly = false;

  ctrl = new FormControl(null, Validators.required);


  @ViewChild('table', { static: true })
  table!: APIDefinition;
  public datas :any =[]; 
  public columns: Columns[] = [
    { key: 'code', title: 'Abregé' },
    { key: 'libelle', title: 'Libelle' },
    { key: 'description', title: 'Description' }
  ];

  public configuration!: Config;
  public pagination = {
    limit: 15,
    offset: 0,
    count: -1
  };
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    public httpService: HttpService,
    private spinner: NgxSpinnerService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.getData('');
  }

  edit(id: number) {
    this.router.navigate(['/editEncadrement', id]);
  }
  delete() {
    
  }
  add() {
    this.router.navigate(['/newEncadrement']);
  }

  subscribeOffre() {
    this.router.navigate(['/offre']);
  }

  
  


  toggle() {
    if (this.ctrl.disabled) {
      this.ctrl.enable();
    } else {
      this.ctrl.disable();
    }
  }
  private getData(params: string): void {
     this.spinner.show();
      this.httpService.getOffre().subscribe(
      response => {
        if(response && response.hasError == false && response['items']){
          this.datas = response['items'];
          this.pagination.count =
           this.pagination.count === -1
             ? response
               ? this.datas.length
               : 0
             : this.pagination.count;
         this.pagination = { ...this.pagination };
        }else
        {
          this.datas =[];
        }
        
        this.spinner.hide();
        this.cdr.detectChanges();
      
      },
      error => {
        console.error('ERROR: ', error.message);
      }
    );
  }

}

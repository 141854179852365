<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
          <h1 class="title">INSCRIPTION</h1>
          <h4 class="subtitle op-8">
            Incrivez-vous , pour bénéficier de notre vaste reseau d'apprentissage
          </h4>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>
  
  <div class="contact3 spacer">
    <div class="row">
      
      <div style="text-align:center">
        <h1>
          Welcome to {{title}}!!
        </h1>
        <form [formGroup]="angForm" novalidate>
              <div class="form-group">
                <label class="center-block">Name:
                  <input class="form-control" formControlName="name">
                </label>
              </div>
              <div *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)" class="alert alert-danger">
                  <div *ngIf="angForm.controls['name'].errors?.required">
                  Name is required.
                </div>
              </div>
        </form>
      <p>Form value: {{ angForm.value | json }}</p>
      <p>Form status: {{ angForm.status | json }}</p>
      </div>

      <div class="col-md-12 no-trans">
        <div class="container">
          <div class="row m-0">
            <div class="col-lg-6 no-trans">
              <div class="card-shadow" data-aos="flip-left" data-aos-duration="1200">
                <img [src]="img" alt="wrapkit" class="img-responsive" />
              </div>
            </div>
            <div class="col-lg-6 no-trans">
              <div class="contact-box">
                
                <form #contact="ngForm" (ngSubmit)="submit(contact)"  data-aos="fade-left"
                  data-aos-duration="1200">
                  <aw-wizard>
                    <aw-wizard-step stepTitle="Information de connexion">
                      <div class="col-lg-12 no-trans">

                        <div class="card">
                          <div class="card-body">
                            <h4 class="card-title">Qui êtes-vous  :</h4>
                            <div
                              class="btn-group btn-group-toggle"
                              ngbRadioGroup
                              name="radioBasic"
                              [(ngModel)]="model"
                            >
                              <label ngbButtonLabel class="btn-primary bg-bleu">
                                <input ngbButton type="radio" [value]="0" /> Je suis apprenant 
                              </label>
                            
                              <label ngbButtonLabel class="btn-warning bg-orange">
                                <input ngbButton type="radio" [value]="1" /> Je suis formateur 
                              </label>
                            </div>
                           
                          </div>
                        </div>
                      </div>
                        <div class="col-lg-12 no-trans">
                          <div class="form-group m-t-10">
                            <input type="text" required class="form-control" placeholder="Login"
                              [class.is-invalid]="name.invalid && name.touched" name="login" #name="ngModel" ngModel />
                          </div>
                        </div>

                        <div  class="col-lg-12 no-trans">
                            <div class="form-group m-t-10">
                              <input type="password" required class="form-control" placeholder="Mot de passe"
                                [class.is-invalid]="name.invalid && name.touched" name="password" #name="ngModel" ngModel />
                            </div>
                          </div> 
                          <div class="col-lg-12 no-trans">
                            <div class="form-group m-t-10">
                              <input type="text"  class="form-control" placeholder="Matricule"
                                [class.is-invalid]="name.invalid && name.touched" name="matricule" #name="ngModel" ngModel />
                            </div>
                          </div>
                          <div class="col-lg-12 no-trans"> 
                            <button type="button"  class="btn btn-outline-primary m-t-20 " awNextStep>   Suivant  <span><i class="fa fa-arrow-right"></i></span> </button>
                          </div> 
                    </aw-wizard-step>
            
                    <aw-wizard-step stepTitle="  Information personnelle " >
                      <div class="col-lg-12 no-trans">
                        <div class="form-group m-t-10">
                          <input type="text" required class="form-control" placeholder="Nom"
                            [class.is-invalid]="name.invalid && name.touched" name="nom" #name="ngModel" ngModel />
                        </div>
                      </div>
                      <div class="col-lg-12 no-trans">
                          <div class="form-group m-t-10">
                            <input type="text" required class="form-control" placeholder="Prenom"
                              [class.is-invalid]="name.invalid && name.touched" name="prenoms" #name="ngModel" ngModel />
                          </div>
                        </div>
                    <div class="col-lg-12 no-trans">
                      <div class="form-group m-t-10">
                        <input type="email" email required class="form-control" placeholder="Addresse email"
                          [class.is-invalid]="email.invalid && email.touched" name="email" #email="ngModel" ngModel />
                      </div>
                    </div>
                    <div class="col-lg-12 no-trans">
                      <div class="form-group m-t-10">
                        <input type="number" required class="form-control" placeholder="Numero de téléphone"
                          [class.is-invalid]="phone.invalid && phone.touched" name="telephone" #phone="ngModel" ngModel />
                      </div>
                    </div>
                  
                    <div class="col-lg-12 no-trans">
                      <div class="form-group m-t-10">
                          <div class="input-group">
                            <input
                              class="form-control"
                              placeholder="Date de naissance"
                              name="dateNaissance"
                              #dateNaissance="ngModel" ngModel 
                             
                              ngbDatepicker
                              #d="ngbDatepicker"
                            />
                            <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"> </button>
                          </div>
                      </div>
                    </div> 
                    <div class="col-lg-12 no-trans"> 
                      <button type="button"  class="btn btn-outline-warning m-t-20 " awPreviousStep>  <span><i class="fa fa-arrow-left"></i></span>  Précedent  </button>
                      <button type="button" style="float: right;"
                     class="btn btn-outline-primary m-t-20 " awNextStep>   Suivant  <span><i class="fa fa-arrow-right"></i></span> </button>
                      
                    </div>
                   
                    </aw-wizard-step>
            
                    <aw-wizard-step stepTitle="Localisation">
                      
                    <div class="col-lg-12 no-trans">
                      <div class="form-group m-t-10">
                        <select   [class.is-invalid]="name.invalid && name.touched" required class="form-control" placeholder="Classe"  [class.is-invalid]="name.invalid && name.touched" name="ville" #name="ngModel" ngModel>
                          <option value="">--Choisiir votre ville--</option>
                          <option *ngFor="let c of cities; let index = index; let isFirst = first; let isOdd = odd;" [value]="c">{{c}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-12 no-trans">
                      <div class="form-group m-t-10">
                        <input type="text" required class="form-control" placeholder="Etablissement"
                          [class.is-invalid]="name.invalid && name.touched" name="etablissement" #name="ngModel" ngModel />
                      </div>
                    </div>
                    <div class="col-lg-12 no-trans">
                      <div class="form-group m-t-10">           
                          <select class="form-control" placeholder="Classe"  [class.is-invalid]="name.invalid && name.touched" name="niveauEtude" #name="ngModel" ngModel>
                            <option value="">--Choisiir votre classe--</option>
                            <option *ngFor="let n of niveau; let index = index; let isFirst = first; let isOdd = odd;" [value]="n.code">{{n.libelle}}</option>
                          </select>
                        
                      </div>
                   
                    </div>
                    <div class="col-lg-12 no-trans"> 
                      <button type="button"  class="btn btn-outline-warning m-t-20 " awPreviousStep>    <span><i class="fa fa-arrow-left"></i></span> Précedent </button>
                    </div> 
                 
                    </aw-wizard-step>
                  </aw-wizard>
            
                  <div class="row">
                   
                    <div class="col-lg-12 no-trans">
                      <button type="submit" class="btn btn-info-gradiant btn-md m-t-20 btn-arrow" [disabled]="contact.invalid">
                        <span> SOUMETTRE <i class="ti-arrow-right"></i></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
 
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Contact Us</h4>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="fclick(contact)">
        <div class="form-group">
          <label class="text-dark font-medium mr-3">Name :</label>
          <label>{{ con.name }}</label>
        </div>
        <div class="form-group">
          <label class="text-dark font-medium mr-3">Email :</label>
          <label>{{ con.email }}</label>
        </div>
        <div class="form-group">
          <label class="text-dark font-medium mr-3">Phone :</label>
          <label>{{ con.phone }}</label>
        </div>
        <div class="form-group">
          <label class="text-dark font-medium mr-3">Message :</label>
          <label>{{ con.message }}</label>
        </div>
        <button type="submit" class="btn btn-info-gradiant btn-block">OK</button>
      </form>
    </div>
  </ng-template>
<div class="static-slider-head" style="background-image: url(../assets/images/landingpage/banner-bg.jpg)">
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
        <h1 class="title"> Élevez votre potentiel à la puissance infinie</h1>
        <h5 class="subtitle op-5">
          Visitez notre site pour découvrir l'actualité sur les concours et les orientations.
          Vous trouverez les cours , excercices , devoirs et examens , des corrigés d'examen , devoirs et bien plus.
        </h5>
      </div>
      <!-- Column -->
    </div>
  </div>
</div>

<!-- Row  -->
<div class="container spacer">
  <div class="row justify-content-center">
    <!-- Column -->
    <div class="col-md-8 text-center no-trans">
      <h2 class="title">Publications récentes</h2>
      <!--   <h6 class="subtitle">
        You can relay on our amazing features list and also our customer
        services will be great experience for you without doubt and in no-time
      </h6>-->
    </div>
    <!-- Column -->
  </div>
  <div class="row m-t-40 blog-home2">
    <!-- Column -->

    <div class="col-md-4" *ngFor="let bl of service.Publications  ">
      <div class="card" data-aos="flip-left" data-aos-duration="1200">
        <img class="card-img-top cursor-pointer" [src]="bl.image" alt="image ici" height="200px"
          (click)="viewDetail(bl.id)" />
        <div class="date-pos bg-info-gradiant">
          <span>{{ bl.blogDate | date: 'dd/MM/yyyy'}}</span>
        </div>
        <h5 class="font-medium m-t-30">{{ bl.titre.substring(0, 50) }}..</h5>
        <p class="m-t-20">
          {{ bl.sousTitre }}
        </p>
        <a class="linking font-medium text-themecolor m-t-10 cursor-pointer">
          <span (click)="viewDetail(bl.id)">voir Details</span>
          <i class="ti-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
<!--
  <div class="d-flex justify-content-center mt-5">
    <ngb-pagination [(page)]="service.pagination.page" [pageSize]="service.pagination.pageSize" [collectionSize]="service.pagination.count"></ngb-pagination>
  </div>
</div>-->

<div
  class="banner-innerpage"
  style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 no-trans align-self-center text-center">
        <h1 class="title">TABS</h1>
        <h6 class="subtitle op-8">
          We are Small Team of Creative People working together
        </h6>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row spacer">
    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Basic Nav</h4>
          <h6 class="card-subtitle">This is basic Default pagination</h6>

          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>One</a>
              <ng-template ngbNavContent>
                <p>
                  Raw denim you probably haven't heard of them jean shorts
                  Austin. Nesciunt tofu stumptown aliqua, retro synth master
                  cleanse. Mustache cliche tempor, williamsburg carles vegan
                  helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                  synth. Cosby sweater eu banh mi, qui irure terry richardson ex
                  squid. Aliquip placeat salvia cillum iphone. Seitan aliquip
                  quis cardigan american apparel, butcher voluptate nisi qui.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Two</a>
              <ng-template ngbNavContent>
                <p>
                  Exercitation +1 labore velit, blog sartorial PBR leggings next
                  level wes anderson artisan four loko farm-to-table craft beer
                  twee. Qui photo booth letterpress, commodo enim craft beer
                  mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo
                  nostrud organic, assumenda labore aesthetic magna delectus
                  mollit. Keytar helvetica VHS salvia yr, vero magna velit
                  sapiente labore stumptown. Vegan fanny pack odio cillum wes
                  anderson 8-bit, sustainable jean shorts beard ut DIY ethical
                  culpa terry richardson biodiesel. Art party scenester
                  stumptown, tumblr butcher vero sint qui sapiente accusamus
                  tattooed echo park.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Three</a>
              <ng-template ngbNavContent>
                <p>
                  Sed commodo, leo at suscipit dictum, quam est porttitor
                  sapien, eget sodales nibh elit id diam. Nulla facilisi. Donec
                  egestas ligula vitae odio interdum aliquet. Duis lectus
                  turpis, luctus eget tincidunt eu, congue et odio. Duis
                  pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et
                  molestie lectus ultrices et. Sed diam urna, egestas ut ipsum
                  vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
                  Vivamus faucibus nisl enim, nec tristique ipsum euismod
                  facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci
                  varius natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                  ultricies in eget neque. Phasellus nec tortor vel tellus
                  pulvinar feugiat.
                </p>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>

    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Alternative markup</h4>
          <h6 class="card-subtitle">
            You can use alternative markup without <code>ul li</code> elements
          </h6>

          <nav ngbNav #navAM="ngbNav" class="nav-tabs">
            <ng-container ngbNavItem>
              <a ngbNavLink>One</a>
              <ng-template ngbNavContent>
                <p>
                  Raw denim you probably haven't heard of them jean shorts
                  Austin. Nesciunt tofu stumptown aliqua, retro synth master
                  cleanse. Mustache cliche tempor, williamsburg carles vegan
                  helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                  synth. Cosby sweater eu banh mi, qui irure terry richardson ex
                  squid. Aliquip placeat salvia cillum iphone. Seitan aliquip
                  quis cardigan american apparel, butcher voluptate nisi qui.
                </p>
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
              <a ngbNavLink>Two</a>
              <ng-template ngbNavContent>
                <p>
                  Exercitation +1 labore velit, blog sartorial PBR leggings next
                  level wes anderson artisan four loko farm-to-table craft beer
                  twee. Qui photo booth letterpress, commodo enim craft beer
                  mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo
                  nostrud organic, assumenda labore aesthetic magna delectus
                  mollit. Keytar helvetica VHS salvia yr, vero magna velit
                  sapiente labore stumptown. Vegan fanny pack odio cillum wes
                  anderson 8-bit, sustainable jean shorts beard ut DIY ethical
                  culpa terry richardson biodiesel. Art party scenester
                  stumptown, tumblr butcher vero sint qui sapiente accusamus
                  tattooed echo park.
                </p>
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
              <a ngbNavLink>Three</a>
              <ng-template ngbNavContent>
                <p>
                  Sed commodo, leo at suscipit dictum, quam est porttitor
                  sapien, eget sodales nibh elit id diam. Nulla facilisi. Donec
                  egestas ligula vitae odio interdum aliquet. Duis lectus
                  turpis, luctus eget tincidunt eu, congue et odio. Duis
                  pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et
                  molestie lectus ultrices et. Sed diam urna, egestas ut ipsum
                  vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
                  Vivamus faucibus nisl enim, nec tristique ipsum euismod
                  facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci
                  varius natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                  ultricies in eget neque. Phasellus nec tortor vel tellus
                  pulvinar feugiat.
                </p>
              </ng-template>
            </ng-container>
          </nav>

          <div [ngbNavOutlet]="navAM" class="mt-2"></div>
        </div>
      </div>
    </div>

    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Vertical pills</h4>
          <h6 class="card-subtitle">
            You should use <code>class="nav-pills"</code> if you need pills and
            the <code>[orientation]</code> input to change nav orientation
          </h6>

          <div class="d-flex">
            <ul
              ngbNav
              #navVp="ngbNav"
              [(activeId)]="active"
              class="nav-pills"
              orientation="vertical"
            >
              <li ngbNavItem="1">
                <a ngbNavLink>Top</a>
                <ng-template ngbNavContent>
                  <p>
                    Raw denim you probably haven't heard of them jean shorts
                    Austin. Nesciunt tofu stumptown aliqua, retro synth master
                    cleanse. Mustache cliche tempor, williamsburg carles vegan
                    helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                    synth. Cosby sweater eu banh mi, qui irure terry richardson
                    ex squid. Aliquip placeat salvia cillum iphone. Seitan
                    aliquip quis cardigan american apparel, butcher voluptate
                    nisi qui.
                  </p>
                </ng-template>
              </li>
              <li ngbNavItem="2">
                <a ngbNavLink>Middle</a>
                <ng-template ngbNavContent>
                  <p>
                    Exercitation +1 labore velit, blog sartorial PBR leggings
                    next level wes anderson artisan four loko farm-to-table
                    craft beer twee. Qui photo booth letterpress, commodo enim
                    craft beer mlkshk aliquip jean shorts ullamco ad vinyl
                    cillum PBR. Homo nostrud organic, assumenda labore aesthetic
                    magna delectus mollit. Keytar helvetica VHS salvia yr, vero
                    magna velit sapiente labore stumptown. Vegan fanny pack odio
                    cillum wes anderson 8-bit, sustainable jean shorts beard ut
                    DIY ethical culpa terry richardson biodiesel. Art party
                    scenester stumptown, tumblr butcher vero sint qui sapiente
                    accusamus tattooed echo park.
                  </p>
                </ng-template>
              </li>
              <li ngbNavItem="3">
                <a ngbNavLink>Bottom</a>
                <ng-template ngbNavContent>
                  <p>
                    Sed commodo, leo at suscipit dictum, quam est porttitor
                    sapien, eget sodales nibh elit id diam. Nulla facilisi.
                    Donec egestas ligula vitae odio interdum aliquet. Duis
                    lectus turpis, luctus eget tincidunt eu, congue et odio.
                    Duis pharetra et nisl at faucibus. Quisque luctus pulvinar
                    arcu, et molestie lectus ultrices et. Sed diam urna, egestas
                    ut ipsum vel, volutpat volutpat neque. Praesent fringilla
                    tortor arcu. Vivamus faucibus nisl enim, nec tristique ipsum
                    euismod facilisis. Morbi ut bibendum est, eu tincidunt odio.
                    Orci varius natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem
                    aliquet ultricies in eget neque. Phasellus nec tortor vel
                    tellus pulvinar feugiat.
                  </p>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="navVp" class="ml-4"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Selecting navs</h4>
          <h6 class="card-subtitle">This is Prevent tab change</h6>

          <ul
            ngbNav
            #navSelected="ngbNav"
            [(activeId)]="activeSelected"
            (navChange)="onNavChange($event)"
            class="nav-tabs"
          >
            <li [ngbNavItem]="1">
              <a ngbNavLink>One</a>
              <ng-template ngbNavContent>
                <p>
                  Raw denim you probably haven't heard of them jean shorts
                  Austin. Nesciunt tofu stumptown aliqua, retro synth master
                  cleanse. Mustache cliche tempor, williamsburg carles vegan
                  helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                  synth. Cosby sweater eu banh mi, qui irure terry richardson ex
                  squid. Aliquip placeat salvia cillum iphone. Seitan aliquip
                  quis cardigan american apparel, butcher voluptate nisi qui.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Two</a>
              <ng-template ngbNavContent>
                <p>
                  Exercitation +1 labore velit, blog sartorial PBR leggings next
                  level wes anderson artisan four loko farm-to-table craft beer
                  twee. Qui photo booth letterpress, commodo enim craft beer
                  mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo
                  nostrud organic, assumenda labore aesthetic magna delectus
                  mollit. Keytar helvetica VHS salvia yr, vero magna velit
                  sapiente labore stumptown. Vegan fanny pack odio cillum wes
                  anderson 8-bit, sustainable jean shorts beard ut DIY ethical
                  culpa terry richardson biodiesel. Art party scenester
                  stumptown, tumblr butcher vero sint qui sapiente accusamus
                  tattooed echo park.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>I can't be selected on click</a>
              <ng-template ngbNavContent>
                <p>
                  Sed commodo, leo at suscipit dictum, quam est porttitor
                  sapien, eget sodales nibh elit id diam. Nulla facilisi. Donec
                  egestas ligula vitae odio interdum aliquet. Duis lectus
                  turpis, luctus eget tincidunt eu, congue et odio. Duis
                  pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et
                  molestie lectus ultrices et. Sed diam urna, egestas ut ipsum
                  vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
                  Vivamus faucibus nisl enim, nec tristique ipsum euismod
                  facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci
                  varius natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                  ultricies in eget neque. Phasellus nec tortor vel tellus
                  pulvinar feugiat.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="4" [disabled]="disabled">
              <a ngbNavLink>I'm disabled</a>
              <ng-template ngbNavContent>
                <p>
                  Sed commodo, leo at suscipit dictum, quam est porttitor
                  sapien, eget sodales nibh elit id diam. Nulla facilisi. Donec
                  egestas ligula vitae odio interdum aliquet. Duis lectus
                  turpis, luctus eget tincidunt eu, congue et odio. Duis
                  pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et
                  molestie lectus ultrices et. Sed diam urna, egestas ut ipsum
                  vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
                  Vivamus faucibus nisl enim, nec tristique ipsum euismod
                  facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci
                  varius natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                  ultricies in eget neque. Phasellus nec tortor vel tellus
                  pulvinar feugiat.
                </p>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="navSelected" class="mt-2"></div>

          <div class="mb-3">
            <button
              class="btn btn-sm btn-outline-primary"
              [disabled]="activeSelected === 2"
              (click)="nav.select(2)"
            >
              Select second tab
            </button>
            <button
              class="btn btn-sm btn-outline-primary ml-2"
              (click)="toggleDisabled()"
            >
              Toggle last disabled
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Keep content</h4>
          <h6 class="card-subtitle">This is Prevent tab change</h6>

          <ul
            ngbNav
            #navKC="ngbNav"
            [(activeId)]="activeKeep"
            [destroyOnHide]="false"
            class="nav-tabs"
          >
            <li [ngbNavItem]="1" [destroyOnHide]="true">
              <a ngbNavLink>One</a>
              <ng-template ngbNavContent>
                <ngb-alert
                  [dismissible]="false"
                  class="d-block mt-3"
                  type="danger"
                >
                  This tab content DOM will de destroyed when not active
                </ngb-alert>

                <p>
                  Raw denim you probably haven't heard of them jean shorts
                  Austin. Nesciunt tofu stumptown aliqua, retro synth master
                  cleanse. Mustache cliche tempor, williamsburg carles vegan
                  helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                  synth. Cosby sweater eu banh mi, qui irure terry richardson ex
                  squid. Aliquip placeat salvia cillum iphone. Seitan aliquip
                  quis cardigan american apparel, butcher voluptate nisi qui.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Two</a>
              <ng-template ngbNavContent>
                <ngb-alert
                  [dismissible]="false"
                  class="d-block mt-3"
                  type="success"
                >
                  This tab content DOM will always stay in DOM
                </ngb-alert>

                <p>
                  Exercitation +1 labore velit, blog sartorial PBR leggings next
                  level wes anderson artisan four loko farm-to-table craft beer
                  twee. Qui photo booth letterpress, commodo enim craft beer
                  mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo
                  nostrud organic, assumenda labore aesthetic magna delectus
                  mollit. Keytar helvetica VHS salvia yr, vero magna velit
                  sapiente labore stumptown. Vegan fanny pack odio cillum wes
                  anderson 8-bit, sustainable jean shorts beard ut DIY ethical
                  culpa terry richardson biodiesel. Art party scenester
                  stumptown, tumblr butcher vero sint qui sapiente accusamus
                  tattooed echo park.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Three</a>
              <ng-template ngbNavContent let-active>
                <ngb-alert
                  *ngIf="active"
                  [dismissible]="false"
                  class="d-block mt-3"
                >
                  While tab content DOM is never destroyed, this alert exists
                  only when current tab is active
                </ngb-alert>

                <p>
                  Sed commodo, leo at suscipit dictum, quam est porttitor
                  sapien, eget sodales nibh elit id diam. Nulla facilisi. Donec
                  egestas ligula vitae odio interdum aliquet. Duis lectus
                  turpis, luctus eget tincidunt eu, congue et odio. Duis
                  pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et
                  molestie lectus ultrices et. Sed diam urna, egestas ut ipsum
                  vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
                  Vivamus faucibus nisl enim, nec tristique ipsum euismod
                  facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci
                  varius natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                  ultricies in eget neque. Phasellus nec tortor vel tellus
                  pulvinar feugiat.
                </p>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="navKC" class="mt-2"></div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Custom style</h4>

          <ul
            ngbNav
            #navCS="ngbNav"
            [activeId]="2"
            class="nav-tabs justify-content-center"
          >
            <li [ngbNavItem]="1">
              <a ngbNavLink>Simple</a>
              <ng-template ngbNavContent>
                <p>
                  Raw denim you probably haven't heard of them jean shorts
                  Austin. Nesciunt tofu stumptown aliqua, retro synth master
                  cleanse. Mustache cliche tempor, williamsburg carles vegan
                  helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher
                  synth. Cosby sweater eu banh mi, qui irure terry richardson ex
                  squid. Aliquip placeat salvia cillum iphone. Seitan aliquip
                  quis cardigan american apparel, butcher voluptate nisi qui.
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink><b>Fancy</b> title</a>
              <ng-template ngbNavContent>
                <p>
                  Exercitation +1 labore velit, blog sartorial PBR leggings next
                  level wes anderson artisan four loko farm-to-table craft beer
                  twee. Qui photo booth letterpress, commodo enim craft beer
                  mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo
                  nostrud organic, assumenda labore aesthetic magna delectus
                  mollit. Keytar helvetica VHS salvia yr, vero magna velit
                  sapiente labore stumptown. Vegan fanny pack odio cillum wes
                  anderson 8-bit, sustainable jean shorts beard ut DIY ethical
                  culpa terry richardson biodiesel. Art party scenester
                  stumptown, tumblr butcher vero sint qui sapiente accusamus
                  tattooed echo park.
                </p>
              </ng-template>
            </li>
            <li ngbDropdown class="nav-item">
              <a href (click)="(false)" class="nav-link" ngbDropdownToggle
                >Dropdown</a
              >
              <div ngbDropdownMenu>
                <button ngbDropdownItem>Action</button>
                <button ngbDropdownItem>Another action</button>
                <button ngbDropdownItem>Something else here</button>
                <div class="dropdown-divider"></div>
                <button ngbDropdownItem>Separated link</button>
              </div>
            </li>
            <li ngbNavItem [disabled]="true" class="ml-auto">
              <a ngbNavLink>Disabled</a>
              <ng-template ngbNavContent>
                <p>
                  Sed commodo, leo at suscipit dictum, quam est porttitor
                  sapien, eget sodales nibh elit id diam. Nulla facilisi. Donec
                  egestas ligula vitae odio interdum aliquet. Duis lectus
                  turpis, luctus eget tincidunt eu, congue et odio. Duis
                  pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et
                  molestie lectus ultrices et. Sed diam urna, egestas ut ipsum
                  vel, volutpat volutpat neque. Praesent fringilla tortor arcu.
                  Vivamus faucibus nisl enim, nec tristique ipsum euismod
                  facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci
                  varius natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                  ultricies in eget neque. Phasellus nec tortor vel tellus
                  pulvinar feugiat.
                </p>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="navCS" class="mt-2"></div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Dynamic navs</h4>

          <ul
            ngbNav
            #navDynamic="ngbNav"
            [(activeId)]="activeDynamic"
            class="nav-tabs"
          >
            <li *ngFor="let id of tabs" [ngbNavItem]="id">
              <a ngbNavLink>
                Tab {{ id }}
                <span
                  class="close position-relative pl-2 font-weight-light"
                  (click)="close($event, id)"
                  >×</span
                >
              </a>
              <ng-template ngbNavContent>
                <p>Tab {{ id }} content</p>
              </ng-template>
            </li>
            <li class="nav-item">
              <a class="nav-link" href (click)="add($event)">...</a>
            </li>
          </ul>

          <div [ngbNavOutlet]="navDynamic" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Blog } from './blog-type';
import { ServiceblogService } from './blog-service.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpService  } from '../../api/http.service';
import { NgxSpinnerService } from "ngx-spinner";

import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogComponent implements OnInit {
  blogsDetail: Blog[] = [];
  
  currentUser : any ;
  currentUserSting : any ;

/*
  roles = ['Visiteur', 'Admin', 'Standart', 'StandartFormateur', 'StandartApprenant', 'PremiumFormateur', 'PremiumApprenant'];
  permissions$ = this.ps.permissions$;
  roles$ = this.rs.roles$;*/

 /* rolePermissions = {
      Guest: ['VISITEUR'],
      Admin: ['ADMIN'],
      Standart: ['STANDART'],
      StandartFormateur: ['STANDARTFORMATEUR'],
      PremiumFormateur: ['PREMIMFORMATEUR'],
      StandartApprenant: ['STANDARTFORMATEUR'],
      PremiumApprenant: ['PREMIMAPPRENANT']
  };

  unsubscribe$ = new Subject();

    hasRole$ = this.roles$.pipe(
        map(permissions => Object.keys(permissions).length > 0),
        takeUntil(this.unsubscribe$),
    );
*/
  constructor(
    public service: ServiceblogService,
    public router: Router,
    public http: HttpClient,
    public httpService: HttpService,
    private spinner: NgxSpinnerService,
    private ps: NgxPermissionsService, private rs: NgxRolesService,
  ) {
    this.service.isLoggedIn();
  }

  ngOnInit(): void {
      this.spinner.show();
      this.service.getPublication().subscribe((d: any) => {
        this.service.Publications = d['items']==undefined ? []:d['items'];
        this.service.pagination.count = d['count'];
        for(var i =0; i<this.service.Publications.length ; i++){
          this.service.Publications[i].blogDate= new Date();
          if(this.service.Publications[i].datasDocument && this.service.Publications[i].datasDocument.length>0){
            this.service.Publications[i].image = this.service.Publications[i].datasDocument[0].pathFile
          }else{
            if(i==1) this.service.Publications[i].image ='assets/images/blog/808.jpg';
            if(i==2) this.service.Publications[i].image ='assets/images/blog/404.jpg';
            if(i==3) this.service.Publications[i].image ='assets/images/blog/101.jpg';
          }
          }
        this.spinner.hide();
     }
      );
    
  }

  loginClick() {
    this.router.navigate(['/login']);
  }

  newPost() {
    this.router.navigate(['/post']);
  }

  viewDetail(id: number) {
   this.router.navigate(['/blogDetail', id]);
  }
  formattedToday() {
  const today = new Date();
  const yyyy = today.getFullYear();
   var mm  =  '';
   var dd  =  '';
  if (today.getMonth() + 1 < 10) dd = '0' + dd;
  else  var dd = today.getDate() +'';
  if (today.getDate() < 10)  mm = '0' + mm;
  else  mm  = ''+ (today.getMonth() + 1);
    return  dd + '/' + mm + '/' + yyyy;
 }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceblogService } from '../blog/blog-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService  } from '../../api/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

class Contact {
  nom: string = '';
  prenons: string = '';
  email: string = '';
  telephone1: string = '';
  telephone2: string = '';
  message: string = '';
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  img = 'assets/images/contact/2.jpg';
  title = 'Are you happy with what we offer? Grab your WrapKit Copy Now';
  subTitle = ' Yeah ! Its pretty cool about what you are offering and i starterd to feel in love with your Amazing UI Kit. I want it Yea!';

  @ViewChild('content', { static: false }) private content: any;

  con: Contact = new Contact();


  contacts = [
    {
      type: 'Address',
      img: 'assets/images/contact/icon1.png',
      contact1: '11 BP 1249 Abj 11',
      contact2: ''
    },
    {
      type: 'Phone',
      img: 'assets/images/contact/icon2.png',
      contact1: '+225 07 09 699 681',
      contact2: ''
    },
    {
      type: 'Email',
      img: 'assets/images/contact/icon3.png',
      contact1: 'treizeconsulting@gmail.com',
      contact2: ''
    },
  ];

  constructor(private service: ServiceblogService, public router: Router,
    public httpService: HttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
     private modalService: NgbModal) {
    this.service.isLoggedIn();
  }

  ngOnInit(): void {
  }

  submit(contact: NgForm) {
    this.con = contact.value;
   /*this.con.prenm = contact.value.email;
    this.con.phone = contact.value.phone;
      this.con.message = contact.value.message;*/
  
    this.open(this.content);
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).
    result.then((result) => 
    {
    }, 
    (reason) => {
      console.log('content',content );
      var data =
      {
        "user":"1",
        "datas":[this.con]  
      }
      this.spinner.show();
      this.httpService.create('contact', data ).subscribe((res: any) =>
      {
        if(res.hasError ==false) this.toastr.success('Succes', 'Opération effectué avec succès');
        else this.toastr.error('Echec', 'Merci de réessayer');
        this.spinner.hide();
      }
    )
  })
  }

  fclick(con:NgForm) {

    this.modalService.dismissAll();
    con.reset();
   
  }

}

<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-md-12 align-self-center text-center no-trans">
        <h1 class="title">DEMANDE D'ENCADREMENT </h1>
        <h6 class="subtitle op-8">
          Creer ou souscrire une offre d'encadrement
        </h6>
      </div>
      <!-- Column -->
    </div>
  </div>
</div>


<div class="container">
<div class="row spacer">
  <div class ="col-lg-12" style="padding-right: 0px;" >
    <a  (click)="add()" style="margin-right: 10px;" class="btn btn-outline-secondary btn-md "
    data-toggle="collapse">
    <span >
      <i class="fa fa-plus"></i>
    </span> Faire une demande d'encadrement
  </a>
  </div>
  <div class="col-md-6 no-trans "  *ngFor="let offre of datas" >
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          Cours de renforcement en 
          <span class="badge badge-success text-white">{{offre.matiereLibelle}}</span>
        </h4>
        <h6 class="card-subtitle">
          Coût de la formation :   <span class="text-bold">{{offre.coutFormation | number}}</span>  FCFA par mois
        </h6>
        <h6 class="card-subtitle">
          Classe : <span class="badge badge-warning text-white">{{offre.classeCode}}</span>
        </h6>
        <h6 class="card-subtitle">
          Dispensé par M.   <span class="text-bold">{{offre.matriculeFk}}</span>
        </h6>
        <ngb-rating [(rate)]="currentRate">
          <ng-template let-fill="fill" let-index="index">
            <span
              class="star"
              [class.filled]="fill === 100"
              [class.bad]="index < 3"
              >&#9733;</span
            >
          </ng-template>
        </ngb-rating>
        <div col-lg-12 style="padding-right: 0px;" >
          <a *ngxPermissionsOnly="['Souscrire-Offre']" (click)="subscribeOffre()" style="margin-right: 10px;" class="btn btn-outline-primary btn-md "
          data-toggle="collapse">
          <span >
            <i class="fa fa-clock"></i>
          </span> Souscrire
        </a>
        <a *ngxPermissionsOnly="['Modifier-Offre']" (click)="edit(offre.id)" style="margin-right: 10px;" class="btn btn-outline-primary btn-md "
          data-toggle="collapse">
          <span>
            <i class="fa fa-eye"></i>
          </span> Modifier
        </a>
        
        <a *ngxPermissionsOnly="['Supprimer-Offre']" (click)="delete()"
        class="btn btn-outline-warning   btn-md  margin-left-3 "   data-toggle="collapse" routerLink="#" >
        <span> 
          <i class="fa fa-delete" aria-hidden="true"></i>Supprimer
        </span>
      </a>

    </div>
    </div>
  </div>
  
</div>
</div>

<div
  class="banner-innerpage"
  style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)"
>
  <div class="container">
    <!-- Row  -->
    <div class="row justify-content-center">
      <!-- Column -->
      <div
        class="col-lg-9 col-md-6 no-trans align-self-center no-trans text-center"
      >
        <h1 class="title">A PROPOS DE NOUS</h1>
      </div>
      <!-- Column -->
    </div>
  </div>
</div>

<!-- ============================================================== -->
<!-- Feature 22  -->
<!-- ============================================================== -->

`<div class="spacer feature22">
  <div class="container">
    <app-top-content></app-top-content>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Feature 22  -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Feature 1  -->
<!-- ============================================================== -->
<div class="bg-light spacer feature1">
  <div class="container">
    <!-- Row  -->
   <!-- <div class="row justify-content-center">
      <div class="col-md-7 text-center no-trans">
        <h2 class="title">Create beautiful Websites in Record Time</h2>
        <h6 class="subtitle">
          You can relay on our amazing features list and also our customer
          services will be great experience for you without doubt and in no-time
        </h6>
      </div>
    </div>-->
    <app-relay-on></app-relay-on>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Feature 1  -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Counter  -->
<!-- ============================================================== -->
<div class="mini-spacer bg-info-gradiant feature24">
  <div class="container">
    <app-achivement></app-achivement>
  </div>
</div>
<!-- ============================================================== -->
<!-- Counter   -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Team 4  -->
<!-- ============================================================== -->
<div class="spacer bg-light team4">
  <div class="container">
    <div class="row justify-content-center m-b-30">
      <div class="col-md-7 text-center no-trans">
        <h2 class="title">Experience professionnele de l'équipe </h2>
        <h6 class="subtitle">
          Nous dispons d'une équipe competente capable de vous proposer des services specifiques à votre besoin 
        </h6>
      </div>
    </div>

    <app-team></app-team>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Team 4  -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Call to action -->
<!-- ============================================================== -->
<div class="spacer">
  <div class="container">
    <div class="row justify-content-center m-b-30">
      <div class="col-md-7 text-center no-trans">
        <h2 class="title">
        Etes-vous curieux
        </h2>
        
        <a
          class="btn btn-info-gradiant btn-md btn-arrow m-t-20"
          data-toggle="collapse"
          href="#"
          ><span>Voir les details <i class="ti-arrow-right"></i></span
        ></a>
      </div>
    </div>
  </div>
</div>
<!-- ============================================================== -->
<!-- End Call to action -->
<!-- ============================================================== -->

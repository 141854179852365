<div class="auth-wrapper d-flex no-block justify-content-center align-items-center"
    style="background:url(assets/images/background/login-register.jpg) no-repeat center center;">

    <div class="auth-box m-3 m-md-0">

        <div id="loginform">
            <div class="logo">
                <span class="db"><img src="assets/images/13educ/logo1.png" alt="logo" /></span>
            </div>
            
            <div class="row">
                <div class="col-12">
                    <form class="form-horizontal m-t-30" id="loginform" #login='ngForm'>

                        <div class="form-group">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="ti-user"></i>
                                </span>
                            </div>
                            <input type="text" required class="form-control form-control-lg" placeholder="Email"
                                aria-label="Username" aria-describedby="basic-addon1"
                                [class.is-invalid]='email.invalid && email.touched' name='email' #email='ngModel'
                                ngModel>
                        </div>
                        </div>


                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon2">
                                    <i class="ti-pencil"></i>
                                </span>
                            </div>
                            <input type="password" required class="form-control form-control-lg" placeholder="Mot de passe"
                                aria-label="Password" aria-describedby="basic-addon1"
                                [class.is-invalid]='password.invalid && password.touched' name='pass'
                                #password='ngModel' ngModel>
                        </div>

                        <div class="form-group text-center">
                            <div class="col-xs-12 p-b-20">
                                <button class="btn btn-block btn-lg bg-bleu" [disabled]='login.invalid'
                                    (click)="submit(login)">Connexion</button>
                            </div>
                        </div>
                     <!--  <ngb-alert [dismissible]="false">
                            <span>Username : admin</span> <br/>
                            <span>Password : admin123</span>
                        </ngb-alert>-->
                    </form>
                    <h4 *ngIf='loginStatus' class="text-danger">Email ou mot de passe  incorrect. Merci de ressayer.</h4>
                </div>
            </div>
        </div>


    </div>
</div>
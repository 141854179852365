
  import {
    Component,
    VERSION,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    OnInit,
    ViewChild
  } from '@angular/core';
  
  import {
    Columns,
    Config,
    DefaultConfig,
    API,
    APIDefinition
  } from 'ngx-easy-table';
  import { CompanyService } from '../company.service';
  import { Subject } from 'rxjs';
  import { HttpService  } from '../../../api/http.service';
  interface EventObject {
    event: string;
    value: {
      limit: number;
      page: number;
    };
  }
  import { Company, data } from '../../../../assets/data';
 
@Component({
  selector: 'app-concours',
  templateUrl: './concours.component.html',
  styleUrls: ['./concours.component.css']
})
export class ConcoursComponent implements OnInit {


  
    @ViewChild('table', { static: true })
    table!: APIDefinition;
    public data :any =[]; 
    public columns: Columns[] = [
      { key: 'code', title: 'Code' },
      { key: 'libelle', title: 'Libellé' },
      { key: 'description', title: 'Description' }
    ];
    public configuration!: Config;
    public pagination = {
      limit: 20,
      offset: 0,
      count: -1
    };
    private ngUnsubscribe: Subject<void> = new Subject<void>();
  
    constructor(
      private companyService: CompanyService,
      private readonly cdr: ChangeDetectorRef,
      public httpService: HttpService
    ) { }
  
    ngOnInit(): void {
      this.configuration = { ...DefaultConfig, searchEnabled: true };
      this.configuration.resizeColumn = true;
      this.configuration.fixedColumnWidth = false;
      this.configuration.showDetailsArrow = true;
      this.configuration.detailsTemplate = true;
      this.configuration.tableLayout.striped = true;
      this.getData('');
    }
  
  
    private getData(params: string): void {
      this.configuration.isLoading = true;
  
        this.httpService.getConcours().subscribe(
        response => {
          if(response &&  response.hasError==false && response['items'])
          {
            this.data = response['items'];
            this.pagination.count =
              this.pagination.count === -1
                ? response
                  ? this.data.length
                  : 0
                : this.pagination.count;
            this.pagination = { ...this.pagination };
            this.cdr.detectChanges();
          }else{
            this.data= [];
          }
          this.configuration.isLoading = false;
        },
        error => {
          console.error('ERROR: ', error.message);
          this.configuration.isLoading = false;
        }
      );
    }
  
    private setRowStyle(): void {
     this.table.apiEvent({
        type: API.setRowStyle,
        value: { row: 1, attr: 'background', value: '#fd5e5ed4' }
      });
    }
  
    eventEmitted($event: { event: string; value: any }): void {
      if ($event.event !== 'onClick') {
        this.parseEvent($event);
      }
    }
  
    private parseEvent(obj: EventObject): void {
      this.pagination.limit = obj.value.limit
        ? obj.value.limit
        : this.pagination.limit;
      this.pagination.offset = obj.value.page
        ? obj.value.page
        : this.pagination.offset;
      this.pagination = { ...this.pagination };
      const params = `_limit=${this.pagination.limit}&_page=${
        this.pagination.offset
      }`; 
      this.getData(params);
    }
  
  
  }
  
<ul class="navbar-nav ml-0 ml-lg-auto">
  <li   class="nav-item" [routerLinkActive]="['active']">
    <a class="nav-link" routerLink="/"> Accueil </a>
  </li>
  <li  class="nav-item" [routerLinkActive]="['active']">
    <a class="nav-link" routerLink="/about"> A propos </a>
  </li>
  <li  class="nav-item" [routerLinkActive]="['active']">
    <a class="nav-link" routerLink="/politique"> Notre politique </a>

  </li>
  <li  class="nav-item" [routerLinkActive]="['active']">
    <a class="nav-link" routerLink="/condition"> conditions générales </a>
  </li>
  
  <li  class="nav-item" [routerLinkActive]="['active']">
    <a class="nav-link" routerLink="/contact"> Contactez-nous </a>
  </li>
  <li *ngxPermissionsOnly="['Consulter-Bibliotheque']"  class="nav-item" [routerLinkActive]="['active']">
    <a class="nav-link" routerLink="/bibliotheque"> Bibliothèque </a>
  </li>
  <li  *ngxPermissionsOnly="['Consulter-Information', 'Consulter-Parcours','Consulter-Orientation','Consulter-Encadrement', 'Consulter-Offre']"  class="nav-item mega-dropdown" ngbDropdown>
    <a ngbDropdownToggle class="nav-link waves-effect waves-dark pro-pic" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>Mon espace</span>
      <i class="ti-angle-down text-white font-12 op-5 ml-2"></i>
    </a>
    <div  class="user-dd border-0" ngbDropdownMenu>
      <div class="row">
        <div class="col-lg-5 inside-bg hidden-md-down">
          <div class="bg-img" style="background-image: url(assets/images/innerpage/mega-bg2.jpg)">
            <h3 class="text-white font-light">
              Visiter vos informations personnel , faites la mise jour quotidienne
            </h3>
          </div>
        </div>
        <div class="col-lg-7">
          <h6>Menu</h6>
          <ul class="list-style-none">
            <li *ngxPermissionsOnly="['Consulter-Information']" >
              <a class="nav-link" style="color: #3e4555;" routerLink="/information"> Mes informations  </a>
            </li>
            <li *ngxPermissionsOnly="['Consulter-Parcours']" >
              <a class="nav-link" style="color: #3e4555;"  routerLink="/parcours"> Parcours scolaire </a>
            </li>
            <li *ngxPermissionsOnly="['Consulter-Offre']" >
              <a class="nav-link" style="color: #3e4555;"  routerLink="/offre"> Offres d'encadrement </a>
            </li>
            <li *ngxPermissionsOnly="['Consulter-Encadrement']" >
              <a class="nav-link"  style="color: #3e4555;" routerLink="/encadrement"> Encadrement Scolaire  </a>
            </li>
            <li *ngxPermissionsOnly="['Consulter-Orientation']">
              <a class="nav-link" style="color: #3e4555;"  routerLink="/orientation"> Orientation </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
  <li *ngxPermissionsOnly="['Consulter-Action', 'Consulter-Profil']"  class="nav-item mega-dropdown" ngbDropdown>
    <a ngbDropdownToggle class="nav-link waves-effect waves-dark pro-pic" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>Administration</span>
      <i class="ti-angle-down text-white font-12 op-5 ml-2"></i>
    </a>
    <div class="user-dd border-0" ngbDropdownMenu>
      <div class="row">
        <div class="col-lg-5 inside-bg hidden-md-down">
          <div class="bg-img" style="background-image: url(assets/images/innerpage/mega-bg2.jpg)">
            <h3 class="text-white font-light">
             Parametrer votre site,  Administrer votre site
            </h3>
          </div>
        </div>
        <div class="col-lg-7">
          <h6>Parametrage</h6>
          <ul class="list-style-none">
            <li *ngFor="let ele of elementsMenuAdmin">
              <a [routerLink]="ele.link">
                {{ ele.Name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
  <li  *ngxPermissionsOnly="['Dev']" class="nav-item mega-dropdown" ngbDropdown>
    <a ngbDropdownToggle class="nav-link waves-effect waves-dark pro-pic" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>Full doc</span>
      <i class="ti-angle-down text-white font-12 op-5 ml-2"></i>
    </a>
    <div class="user-dd border-0" ngbDropdownMenu>
      <div class="row">
        <div class="col-lg-5 inside-bg hidden-md-down">
          <div class="bg-img" style="background-image: url(assets/images/innerpage/mega-bg2.jpg)">
            <h3 class="text-white font-light">
             Parametrer votre site,  Administrer votre site
            </h3>
          </div>
        </div>
        <div class="col-lg-7">
          <h6>Parametrage</h6>
          <ul class="list-style-none">
            <li *ngFor="let ele of elementsMenu">
              <a [routerLink]="ele.link">
                {{ ele.Name }}
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </li>
</ul>



<div class="act-buttons d-none d-md-flex ml-md-auto ml-lg-0">
  <ul class="navbar-nav align-items-center">
    <li class="nav-item">
      <a class="btn btn-danger-gradiant font-14" *ngIf="!service.loginStatusService" (click)="loginClick()">
        Se connecter
      </a><a class="btn btn-success-gradiant font-14" *ngIf="!service.loginStatusService" (click)="suscribeClick()">
        S'inscrire
      </a>
      <a class="btn btn-info-gradiant font-14" *ngIf="service.loginStatusService" (click)="newPost()">
        Nouvelle Pub
      </a>
    </li>
    
    <li class="nav-item" *ngIf="service.loginStatusService" ngbDropdown>
      <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img src="assets/images/users/1.jpg" class="rounded-circle ml-2" alt="Cinque Terre" width="40" height="40"
          (click)="fun()" />
        <i class="ti-angle-down text-white font-12 op-5 ml-2"></i>
      </a>
      <div class="user-dd border-0 dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <span class="dropdown-item">
          Bievenue  {{currentUser.prenoms + ' ' + currentUser.nom}}  
        </span>
        <a class="dropdown-item" (click)="logoutClick()" href="javascript:void(0)">
          <i class="fa fa-power-off m-r-5 m-l-5"></i> Se deconnecter
        </a>
      </div>
    </li>
  </ul>
</div>
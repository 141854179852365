<div class="topbar" id="top">
  <div class="header6">
    <div class="container po-relative">
      <nav class="navbar navbar-expand-lg h6-nav-bar">
        <a class="navbar-brand" routerLink="">
          <img src="../assets/images/13educ/logo13edu.jpg" alt="13Edu" />
        </a>

        <a
          class="topbartoggler d-block d-lg-none waves-effect waves-light text-white"
          href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          <i class="ti-more"></i>
        </a>
        <div
          class="collapse navbar-collapse hover-dropdown font-14 ml-auto"
          [ngbCollapse]="!isCollapsed"
          id="h6-info"
        >
          <app-banner-navigation
            class="ml-0 ml-lg-auto d-flex no-block align-items-center w-100"
          ></app-banner-navigation>
        </div>
      </nav>
    </div>
  </div>
</div>

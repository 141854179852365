
<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
          <h1 class="title">DEMANDES D'ENCADREMENT</h1>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>
  
  <div class="contact3 spacer">
    <div class="row">
      <div class="col-md-12 no-trans">
        <div class="container">
            <div class="contact-box m-l-30">
                <h1 class="title font-light m-t-10 color-orange">Liste des demandes d'encadrement</h1>
                <ngx-table
                #table
                [configuration]="configuration"
                [data]="datas"
                [columns]="columns"
                [pagination]="pagination"
                (event)="eventEmitted($event)"
              >
              </ngx-table>
          </div>
        </div>
      </div>
    </div>
  </div>




import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceblogService } from '../blog/blog-service.service';
import { NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService  } from '../../api/http.service';
import { User  } from '../model/model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

class Contact {
  name: string = '';
  email: string = '';
  phone: string = '';
  message: string = '';
}

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.css'],
 
})
export class InscriptionComponent implements OnInit {
  model = 0;

  img = 'assets/images/contact/2.jpg';
  title = 'Are you happy with what we offer? Grab your WrapKit Copy Now';
  subTitle = ' Yeah ! Its pretty cool about what you are offering and i starterd to feel in love with your Amazing UI Kit. I want it Yea!';

  @ViewChild('content', { static: false }) 
  private content: any;
  public niveau: any;
  cities :any;

  con: Contact = new Contact();
  user: User  = new User();
  form: User  = new User();

  contacts = [
    {
      type: 'Address',
      img: 'assets/images/contact/icon1.png',
      contact1: '601 Sherwood Ave.San Bernandino',
      contact2: ''
    },
    {
      type: 'Phone',
      img: 'assets/images/contact/icon2.png',
      contact1: '251 546 9442',
      contact2: '630 446 8851'
    },
    {
      type: 'Email',
      img: 'assets/images/contact/icon3.png',
      contact1: 'info@wrappixel.com',
      contact2: '123@wrappixel.com'
    },
  ];
   angForm!: FormGroup;
  constructor(private service: ServiceblogService, public router: Router, private modalService: NgbModal,
    private spinner: NgxSpinnerService,  private toastr: ToastrService,public httpService: HttpService ,
    private fb: FormBuilder) {
    this.service.isLoggedIn();
  }

 
   createForm() {
    this.angForm = this.fb.group({
       name: ['', Validators.required ]
    });
  }
  ngOnInit(): void {
    this.spinner.show();
    this.httpService.getCities().subscribe(
      (res: any) => {
      if(res.error ==false) this.cities = res.data;
      else this.cities = [];
     this.spinner.hide();
   });
    this.httpService.getNiveauEtude().subscribe(
      (res: any) => {
      if(res.hasError ==false) this.niveau = res.items;
      else this.niveau = [];
     
     this.spinner.hide();
   })
    
  }

  submit(contact: NgForm) {
    this.spinner.show();
    
    this.form = contact.value;
    console.log('codeProfil ',  this.form)
    console.log('model ',  this.model);
    var dateNaissance =contact.value.dateNaissance;
    
    this.form.codeProfil= this.model ==1 ? 'STANDART_FORMATEUR' :'STANDART_APPRENANT' ;
    this.form.dateNaissance = dateNaissance.day +'/' +dateNaissance.month +'/' +dateNaissance.year
    var data = 

    {
      //"user":"1",
      "datas":[Object.assign(this.user, this.form )]
    }
     console.log('d',data );
    
      this.httpService.create('utilisateur', data ).subscribe((res: any) => {
        if(res.hasError ==false) {
          this.toastr.success('Succes', 'Opération effectué avec succès');
          this.router.navigate(['/login']);
        }
        else this.toastr.error('Echec', res.status.message);
   
      this.spinner.hide();
     }
    );

    //this.open(this.content);

  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }



  fclick(con:NgForm) {

    this.modalService.dismissAll();
    con.reset();
   
  }
  
  anyMethod(): void {
 /*   this.wizard.tabs: Array<TabDirective> //=> list of active(index must be >= 0) tabs, only avaliable after View Init
    this.wizard.activeTab: TabDirective //=> get the activeTab
    
    //you can manualy fire nextTab
    wizard.nextTab(false/*emitContinue);*/

}



}


  import {
    Component,
    VERSION,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    OnInit,
    ViewChild
  } from '@angular/core';
  
  import {
    Columns,
    Config,
    DefaultConfig,
    API,
    APIDefinition
  } from 'ngx-easy-table';
  import { CompanyService } from '../company.service';
  import { Subject } from 'rxjs';
  import { HttpService  } from '../../../api/http.service';
  interface EventObject {
    event: string;
    value: {
      limit: number;
      page: number;
    };
  }
  import { Company, data } from '../../../../assets/data';
  @Component({
    selector: 'app-matiere',
    templateUrl: './matiere.component.html',
    styleUrls: ['./matiere.component.css']
  })
  export class MatiereComponent implements OnInit {
  
   
    @ViewChild('table', { static: true })
    table!: APIDefinition;
    public data :any =[]; 
    public columns: Columns[] = [
      { key: 'code', title: 'Abregé' },
      { key: 'libelle', title: 'Libelle' },
      { key: 'description', title: 'Description' }
    ];
  
    public configuration!: Config;
    public pagination = {
      limit: 15,
      offset: 0,
      count: -1
    };
    private ngUnsubscribe: Subject<void> = new Subject<void>();
  
    constructor(
      private companyService: CompanyService,
      private readonly cdr: ChangeDetectorRef,
      public httpService: HttpService
    ) { }
  
    ngOnInit(): void {
      this.configuration = { ...DefaultConfig, searchEnabled: true };
      this.configuration.resizeColumn = true;
      this.configuration.fixedColumnWidth = false;
      this.configuration.tableLayout.striped = true;
      this.getData('');
      this.pagination.limit = 10;
    }
  
  
    private getData(params: string): void {
      this.configuration.isLoading = true;
  
        this.httpService.getMatiere().subscribe(
        response => {
          this.data = response['items'];
           this.pagination.count =
            this.pagination.count === -1
              ? response
                ? this.data.length
                : 0
              : this.pagination.count;
          this.pagination = { ...this.pagination };
         // this.pagination.limit = 15;
         
          this.configuration.isLoading = false;
          this.cdr.detectChanges();
         // this.setRowStyle();
        },
        error => {
          console.error('ERROR: ', error.message);
        }
      );
    }
  
    private setRowStyle(): void {
     this.table.apiEvent({
        type: API.setRowStyle,
        value: { row: 1, attr: 'background', value: '#fd5e5ed4' }
      });
    }
  
    eventEmitted($event: { event: string; value: any }): void {
      if ($event.event !== 'onClick') {
        this.parseEvent($event);
      }
    }
  
    private parseEvent(obj: EventObject): void {
      this.pagination.limit = obj.value.limit
        ? obj.value.limit
        : this.pagination.limit;
      this.pagination.offset = obj.value.page
        ? obj.value.page
        : this.pagination.offset;
      this.pagination = { ...this.pagination };
      const params = `_limit=${this.pagination.limit}&_page=${
        this.pagination.offset
      }`; 
      this.getData(params);
    }
  
  
  }
  

export class User extends Object {
  login:string='';
  email:string='';
  password:string='';
  matricule:string='';
  nom:string='';
  prenoms:string='';
  ville:string='';
  pays: string = 'CI';
  dateNaissance: string = '';
  typeUtuliseurFk: number =1;
  etat:string='Brouillon';
  codeProfil: string = '';
  description:string='';
  niveauEtude:string='';
  telephone:string='';
}

  export class ModelSdt {
    code: string = '';
    libelle: string = '';
    description: string = '';
  };

  export class Action {
    code: string = '';
    libelle: string = '';
    description: string = '';
  };
  export class Ecole {
    abrege: string = '';
    libelle: string = '';
    description: string = '';
  };
 
  export class Fonctionnalite {
    code: string = '';
    libelle: string = '';
    description: string = '';
  };
  export class Profil {
    code: string = '';
    libelle: string = '';
    description: string = '';
  };

  export class Documents {
    id!: number;
    libelle: string = '';
    typeDocumentFk: string = '';
    description: string = '';
    ressources: string = '';
    nomFichier: string = '';
    extension: string = '';
    cheminFichier: string = '';
    image: string = '';
    
  };

  export class Niveaux {
    matiereLibelle: string = '';
    matiereCode: string = ''; 
    id: number = 0;
    ma: number = 0;
    mex: number = 0;
    coeficient: number = 0;
  }


  export class Offre {
    matriculeFk: string  = '';
  coutFormation: number = 0;
  matiereFk: string = '';
  codeClasseFk: string = '';
  periodeFacturation: string = '';
}
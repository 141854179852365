export class Blog {
    id: number = 0;
    image:string="";
    heading: string = '';
    subHeading: string = '';
    blogDate:string='';
    blogDetail: string = '';
}
export class Publication {
    id : any;
    idFicher: number = 1;
    image:string="https://www.pexels.com/photo/close-up-photo-of-glowing-blue-butterflies-326055";
    titre: string = '';
    sousTitre: string = '';
    blogDate:string='';
    descrition: string = '';
    typeDocumentCode : string  = 'IMAGE';
    datasDocument : any[]=[];
       
}
<div
  class="banner-innerpage"
  style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 no-trans align-self-center text-center">
        <h1 class="title">PROGRESS BAR</h1>
        <h6 class="subtitle op-8">
          We are Small Team of Creative People working together
        </h6>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row spacer">
    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Basic Progressbar</h4>
          <h6 class="card-subtitle">This is Basic Progressbar</h6>
          <p><ngb-progressbar type="success" [value]="25"></ngb-progressbar></p>
          <p><ngb-progressbar type="info" [value]="50"></ngb-progressbar></p>
          <p>
            <ngb-progressbar
              type="primary"
              [value]="150"
              [max]="200"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              type="inverse"
              [value]="150"
              [max]="150"
            ></ngb-progressbar>
          </p>
          <p><ngb-progressbar type="warning" [value]="75"></ngb-progressbar></p>
          <p><ngb-progressbar type="danger" [value]="100"></ngb-progressbar></p>
        </div>
      </div>
    </div>
    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Progress bars with current value labels</h4>
          <h6 class="card-subtitle">
            This is Progress bars with current value labels
          </h6>
          <p>
            <ngb-progressbar
              [showValue]="true"
              type="success"
              [value]="25"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              [showValue]="true"
              type="info"
              [value]="50"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              [showValue]="true"
              type="primary"
              [value]="150"
              [max]="200"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              [showValue]="true"
              type="inverse"
              [value]="150"
              [max]="150"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              [showValue]="true"
              type="warning"
              [value]="150"
              [max]="200"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              [showValue]="true"
              type="danger"
              [value]="150"
              [max]="150"
            ></ngb-progressbar>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Striped progress bars</h4>
          <h6 class="card-subtitle">This is Striped progress</h6>
          <p>
            <ngb-progressbar
              type="success"
              [value]="25"
              [striped]="true"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              type="info"
              [value]="50"
              [striped]="true"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              type="warning"
              [value]="75"
              [striped]="true"
            ></ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar
              type="danger"
              [value]="100"
              [striped]="true"
            ></ngb-progressbar>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 no-trans">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Progress bars with custom labels</h4>
          <h6 class="card-subtitle">This is basic Default pagination</h6>
          <p>
            <ngb-progressbar type="success" [value]="25">25</ngb-progressbar>
          </p>
          <p>
            <ngb-progressbar type="info" [value]="50"
              >Copying file <b>2 of 4</b>...</ngb-progressbar
            >
          </p>
          <p>
            <ngb-progressbar
              type="warning"
              [value]="75"
              [striped]="true"
              [animated]="true"
              ><i>50%</i></ngb-progressbar
            >
          </p>
          <p>
            <ngb-progressbar type="danger" [value]="100" [striped]="true"
              >Completed!</ngb-progressbar
            >
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Progress bars with heights</h4>
          <h6 class="card-subtitle">This is basic Default pagination</h6>
          <p>
            <ngb-progressbar type="success" [value]="25"
              >default</ngb-progressbar
            >
          </p>
          <p>
            <ngb-progressbar type="info" [value]="50" height="10px"
              >10px</ngb-progressbar
            >
          </p>
          <p>
            <ngb-progressbar type="warning" [value]="75" height=".5rem"
              >.5rem</ngb-progressbar
            >
          </p>
          <p>
            <ngb-progressbar type="danger" [value]="100" [height]="height">{{
              height
            }}</ngb-progressbar>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Global configuration of progress bars</h4>
          <h6 class="card-subtitle">
            This is Global configuration of progress bars
          </h6>
          <p>This progress bar uses the customized default values.</p>
          <p><ngb-progressbar [value]="250"></ngb-progressbar></p>

          <p>
            This progress bar uses the customized default values, but changes
            the type using an input.
          </p>
          <p><ngb-progressbar [value]="500" type="info"></ngb-progressbar></p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
          <h1 class="title">Mes informations </h1>
          <h6 class="subtitle op-8">
            Page en contruction
          </h6>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>


  <div class="container spacer">
    <div class="row justify-content-center">
      <!-- Column -->
      <div class="col-md-8 text-center no-trans">
        <h2 class="title">Page en contruction</h2>
      </div>
      <!-- Column -->
    </div>
</div>
<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
          <h1 class="title">Nouvelle offre</h1>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>
  
  <div class="container">
    <div class="row justify-content-center pt-5">
      <div class="col-md-8 no-trans">
        <form #post="ngForm" (ngSubmit)="submit(post)">
                
          <div class="form-group">
            <label class="text-dark font-medium mr-3">Matière :</label>
              <select   [class.is-invalid]="name.invalid && name.touched" required class="form-control"  [class.is-invalid]="name.invalid && name.touched" name="matiereFk" #name="ngModel" ngModel>
                <option value="">--Choisiir une matière--</option>
                <option *ngFor="let m of matieres; let index = index; let isFirst = first; let isOdd = odd;" [value]="m.code">{{m.code  + '-'+ m.libelle}}</option>
              </select>
          </div>
          <div class="form-group">
            <label class="text-dark font-medium mr-3">Classe :</label>
           
              <select   [class.is-invalid]="name.invalid && name.touched" required class="form-control"  [class.is-invalid]="name.invalid && name.touched" name="codeClasseFk" #name="ngModel" ngModel>
                <option value="">--Choisiir une matière--</option>
                <option *ngFor="let c of classes; let index = index; let isFirst = first; let isOdd = odd;" [value]="c.code">{{c.libelle}}</option>
              </select>
          </div>
          <div class="form-group">
            <label class="text-dark font-medium mr-3">Coût de la formation:</label>
            <input type="number" required class="form-control" placeholder="Coût de la formation"
              [class.is-invalid]="subheading.invalid && subheading.touched" name="coutFormation" #subheading="ngModel"
              ngModel />
          </div>
          <div class="form-group">
            <label class="text-dark font-medium mr-3">Période de facturation</label>
            
              <select   [class.is-invalid]="name.invalid && name.touched" required class="form-control"  [class.is-invalid]="name.invalid && name.touched" name="periodeFacturation" #name="ngModel" ngModel>
                <option value="">--Choisiir une matière--</option>
                <option *ngFor="let p of periodes; let index = index; let isFirst = first; let isOdd = odd;" [value]="p.code">{{p.libelle}}</option>
              </select>
          </div>
         
          <div class="form-group"> 
            <label class="text-dark font-medium mr-3">Description :</label>
            <textarea required class="form-control" rows="5" placeholder="Description"
              [class.is-invalid]="textBody.invalid && textBody.touched" name="textBody" #textBody="ngModel" ngModel>
            </textarea>
          </div>
          <button type="submit" class="btn btn-info-gradiant btn-block bg-bleu " [disabled]="post.invalid">
            Enregistrer l'offre
          </button>
        </form>
        <hr class="op-5" />
      </div>
    </div>
  </div>


  
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class CompanyService {
  private readonly BACKEND_URL =
    "https://my-json-server.typicode.com/ssuperczynski/ngx-easy-table/company?";

  constructor(private http: HttpClient) {}

  getCompanies(params :any) {
    return this.http.get(`${this.BACKEND_URL}${params}`);
  }
}

<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
         
          <h6 class="subtitle op-8">
          Politique de confidentialité
          </h6>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>

  <div class="container spacer" style="color: black !important;">
   <h3>
    POLITIQUE DE CONFIDENTIALITÉ ET DE PROTECTION DES DONNÉES PERSONNELLES
   </h3>
   
       <ol>
        <li> <span class="text-bold">Déclaration générale </span>
            <br>
            Nous prenons votre vie privée très au sérieux et nous nous engageons à la protéger. Nous
            Considérons que vous devez pouvoir facilement savoir quelles sont les données à
            caractère Personnel que nous recueillons et utilisons, et comprendre vos droits en lien
            avec celles-ci.
            La présente politique de confidentialité (la « Politique de confidentialité ») explique nos
            politiques et pratiques quant à la manière dont nous recueillons, utilisons et divulguonsles données à caractère personnel que nous recueillons sur nos plateformes numériques
            ou pendant nos événements.
            Nous vous recommandons de lire attentivement la présente Politique de confidentialité
            car elle contient des informations importantes sur vos données à caractère personnel.
            Vous pouvez imprimer le texte intégral de notre Politique de confidentialité en cliquant
            ici. <br>
            N’hésitez pas à nous contacter si vous avez des questions ou des remarques sur notre
            Politique de confidentialité (voir la rubrique « Comment nous contacter » ci-dessous).
        <br>
    </li>
        <li> <span class="text-bold">  Qui sommes-nous ? </span><br>
            <p>
                Les termes « Treize Consulting », « nous », « notre » et « nos » désignent l’entreprise
                Treize Consulting en tant que responsable du traitement de vos données à caractère
                personnel, sauf indication contraire dans notre Politique de confidentialité.
                
            </p>
            <p> 
                TREIZE CONSULTING, est une société à responsabilité limitée unipersonnel au
                capital de 1000 000 Franc CFA, dont le siège social est situé Abidjan, Cocody, Angré 8ème
                Tranche, Carrefour prière, lot : 682, ILOT 44, inscrite au Registre du Commerce et du
                crédit mobilier sous le numéro RCCM CI-ABJ-03-2024-B13-05198.
                Treize consulting est hébergeur et éditeur de l’application mobile Treize éducation
                désigné par (13.Edu).
            </p>
            <p>

TREIZE éducation est une solution éducative technologique qui utilise la technologie
dans l'éducation pour faciliter la création et l'adaptation de contenus éducatifs, offrant ainsi
des ressources éducatives en libre accès favorisant la réutilisation et l'adaptation des
contenus.
Au sein de la présente Politique de confidentialité, toute référence à 13.EDU désignera
la société éditrice directement ou indirectement par Treize Consulting.
Vous trouverez nos coordonnées dans la rubrique « Comment nous contacter » cidessous.</p>
        </li>
        <li>
            <span class="text-bold">   Quelles sont les données à caractère personnel que nous recueillons et comment sontelles recueillies ?
</span><br>
    Les données à caractère personnel sont des informations relatives à une personne
physique ou morale identifiée ou identifiable. Elles incluent par exemple le nom,
l’adresse, la date de naissance et le genre d’une personne.
Nous pouvons recueillir des données à caractère personnel directement auprès de vous
(par exemple lorsque vous candidatez à l’un de nos programmes) ou indirectement (par
exemple à partir de vos appareils électroniques qui interagissent avec notre site Internet
(voir notre application mobile 13.EDU)
        <p> <br>
            <span class="text-bold"> 3.1 Données que vous nous fournissez directement

            </span>
            <br> Vous pouvez nous fournir des données :
            <br>  Lorsque vous candidatez à l’un de nos programmes ;
            <br>  Lorsque vous vous abonnez à notre lettre d’information via notre site internet ;
            <br>  Lorsque vous participez à l’un de nos événements ;
           <br>  Lorsque vous nous contactez via notre module de contact mis en ligne sur notre site
            internet
            
            =>Selon ce que vous nous fournissez, lesdites informations peuvent inclure :
            Votre identité (y compris votre prénom, nom de famille, genre, image) ;
            Vos coordonnées (y compris votre (vos) adresse(s) postale(s), e-mail(s), numéro(s) de
            téléphone) ;
            Votre statut personnel (y compris votre titre de civilité) ;
            D’autres informations que vous pouvez fournir en remplissant des formulaires ou en
            nous contactant (y compris vos commentaires ou d’autres communications avec nous).
         
            
        </p>
        <p>
            =>Lorsque vous proposez votre candidature à l’un de nos programmes, lesdites
informations peuvent inclure :
Votre statut professionnel et académique (profession, titre, …);
Votre situation financière (dans le cas de l’attribution de bourses) ;
Certaines données de paiement (tel que des coordonnées bancaires aux fins de
règlement de prestations, versements ou de soutien) ;
Nous vous informerons lorsque vos informations sont nécessaires pour traiter votre
demande, pour répondre à vos demandes ou pour vous permettre de participer à l’un de
nos programmes. Si vous ne donnez pas ces informations, cela peut retarder ou rendre
impossible le traitement de votre demande.
Nous nous efforçons de veiller à ce que les données à caractère personnel que nous
détenons soient exactes à tout moment. Nous vous encourageons donc à mettre à jour
vos données au cas où des changements se produiraient. Nous pouvons également vous
demander de mettre à jour vos données de temps à autre.Nous vous recommandons de ne fournir que les données demandées ou nécessaires à
votre requête, et de ne pas nous communiquer de données sensibles relatives à la race, à
l’origine ethnique, aux opinions politiques, aux croyances religieuses ou philosophiques,
et des données concernant la santé, la vie sexuelle ou l’orientation sexuelle.
        </p>
        <p>
            <span class="text-bold">
                3.2 Données recueillies indirectement </span>
<br>
Nous pouvons recueillir des données lorsque vous utilisez notre site, notamment votre
adresse IP ou d’autres données de navigation (y compris le navigateur, le système
d’exploitation, le modèle de l’appareil), par le biais de cookies ou de technologies
similaires placés sur votre appareil. Certains cookies sont nécessaires au bon
fonctionnement de notre site et d’autres sont utilisés à des fins d’analyse qui nous aident
à vous fournir des services plus personnalisés et une meilleure expérience numérique.
Nous pouvons également recueillir des données vous concernant auprès de tiers,
notamment d’un conjoint qui nous contacte en votre nom ou celui de vos amis qui nous
fournissent vos coordonnées afin de vous inviter à des événements susceptibles de vous
intéresser. <br>
Si vous nous fournissez des données à caractère personnel concernant quelqu’un d’autre,
vous devez vous assurer que vous avez le droit de nous divulguer ces données et que, sans
que nous prenions d’autres mesures imposées par la législation en matière de protection
des données, nous pouvons recueillir, utiliser et divulguer lesdites données pour les
finalités décrites dans notre Politique de confidentialité. Vous devez par exemple vous
assurer que la personne concernée a connaissance des divers sujets exposés dans notre
Politique de confidentialité. La personne doit également donner les consentements
énoncés dans notre Politique de confidentialité en ce qui concerne la manière dont nous
traitons ses données à caractère personnel.
        </p>
        </li>

        <li> <span class="text-bold"> 
            Pourquoi recueillons-nous vos données à caractère personnel et comment les utilisonsnous ?
        </span>
    <br>

            Nous recueillons et utilisons vos données à caractère personnel en nous fondant sur une ou plusieurs des bases juridiques suivantes :
            <br> Nous avons obtenu votre consentement préalable (par exemple lorsque vous vous abonnez à notre lettre d’information). Veuillez noter qu’avec cette base juridique particulière, vous avez le droit de révoquer votre consentement à tout moment (voir ci-dessous « Quels sont vos droits sur vos données à caractère personnel ? ») ;
            <br> le traitement est nécessaire dans le cadre d’un contrat entre Treize Consulting et vous ;
            <br> Nous avons un intérêt légitime pour effectuer le traitement et cet intérêt légitime n’est pas éclipsé par vos intérêts, droits fondamentaux ou libertés ;
            <br> Nous devons traiter vos données à caractère personnel afin de respecter les lois et la règlementation en vigueur.
            <br>Selon le contexte, nous pouvons utiliser vos données à caractère personnel pour :
            <br> traiter votre candidature à l’un de nos programmes ;
            <br> effectué des vérifications pour vous identifier et vérifier votre identité ;
            <br>  vous envoyer une lettre d’information (avec votre consentement préalable) ou des invitations à l’un de nos événements ;
            <br> répondre à vos questions, demandes et suggestions ;
            <br> gérer les événements auxquels vous vous êtes inscrit et/ou auxquels vous avez participé ;
            <br> surveiller et améliorer nos Plateformes numériques ;
            <br> effectuer des analyses statistiques ;
            <br> fournir des informations aux organismes de réglementation lorsque la loi l’exige.
 </li>



        <li> 
            <span class="text-bold">
            Combien de temps conservons-nous vos données à caractère personnel ?
            </span> <br>
            Vos données à caractère personnel sont traitées pendant la période nécessaire pour les finalités pour lesquelles elles ont été recueillies, pour se conformer aux obligations légales et réglementaires et pour la durée de toute période nécessaire à l’établissement, l’exercice ou la défense de droits légaux.
            Afin de déterminer les durées de conservation les plus appropriées pour vos données à caractère personnel, nous avons spécifiquement pris en compte la quantité, la nature et la sensibilité de vos données à caractère personnel, les raisons pour lesquelles nous avons recueilli vos données à caractère personnel, ainsi que les exigences légales applicables. Par exemple :
            <br> En ce qui concerne les candidats à nos programmes d’études ou à nos projets : vos données sont conservées pendant un an à compter de leur collecte puis supprimées ;
            <br> En ce qui concerne les destinataires de la lettre d’information : vos données sont conservées pendant trois ans à compter de votre dernière action, puis supprimées ;
            <br> En ce qui concerne les bénéficiaires de nos programme de cours à domicile : vos données sont conservées pendant le maximum d’attribution de cours et jusqu’à cinq ans maximum, puis supprimées ou archivées afin de respecter les obligations légales de conservation ;
            <br> En ce qui concerne les cookies utilisés: ils sont stockés jusqu’à treize mois à partir du moment où ils ont été installés sur votre appareil.
        
        
        </li>
        
        <li> 
                <span class="text-bold">   Comment divulguons-nous et transférons-nous vos données à caractère personnel ?
        
            </span> <br>
            Nous ne pouvons divulguer vos données à caractère personnel qu’aux parties désignées ci-dessous et pour les raisons suivantes :
            <br> Nous divulguons vos données à caractère personnel aux employés de 13.Edu qui ont besoin d’avoir accès à vos données à caractère personnel et qui sont autorisés à les traiter pour les finalités susmentionnées et qui s’engagent à en respecter la confidentialité.
            <br> Nous pouvons être amenés à divulguer vos données à caractère personnel, de la communication, de l’audit interne et de la gestion informatique pour les finalités énoncées dans notre Politique de confidentialité avec votre consentement, de vous adresser des communications promotionnelles concernant des événements (à cette fin, vous pouvez révoquer votre consentement à tout moment. Consultez la rubrique « Quels sont vos droits sur vos données à caractère personnel ? » ci-dessous).
            <br> Nous pouvons également être amenés à communiquer des données à caractère personnel à des tiers agissant pour le compte de Treize Consulting et agréés par elle. Pour le traitement des données que vous renseignez lorsque vous accédez à  l’espace de notre site internet. Tous ces traitements sont basés sur nos instructions préalables énoncées dans un contrat contraignant qui est conforme aux exigences de la loi applicable. Lesdites divulgations sont faites pour des motifs différents, notamment :
            Le développement, l’assistance informatiques et l’envoi d’emails ;
            La mise à disposition de formulaires numériques permettant de gérer vos candidatures à nos programmes ou de gérer les relations presse et client.
            Ces tiers s’engagent à respecter la confidentialité et ne sont pas autorisés à utiliser vos données à caractère personnel pour toute autre finalité. Nous leur imposons également l’application de mesures de sécurité appropriées pour protéger vos données à caractère personnel. 
            Pour obtenir une description des mesures de protection appropriées, vous pouvez nous envoyer votre demande en utilisant les données de la rubrique « Comment nous contacter ? » ci-dessous.
            <br> Nous pouvons être tenus par les dispositions obligatoires d’une loi applicable ou dans le cadre d’une procédure judiciaire ou autre
        
            demande légale, de divulguer vos données à caractère personnel à des autorités ou à des tiers.
            br> Nous pouvons également divulguer ou traiter vos données à caractère personnel conformément à la loi applicable pour défendre nos intérêts légitimes (par exemple dans le cadre d’une procédure civile ou pénale).
        
        
        </li>

        <li> <span class="text-bold">
            Comment protégeons-nous vos données à caractère personnel ?
            </span> <br>
            Toutes vos données à caractère personnel sont strictement confidentielles et ne seront accessibles qu’en cas de nécessité, uniquement par le personnel de Treize, ainsi que les prestataires indépendants agissant en notre nom dans le cadre de mesures de sécurité techniques et organisationnelles appropriées.
            13.Edu a mis en place des mesures de sécurité pour protéger vos données à caractère personnel contre tout accès et utilisation non autorisés. Nous suivons des procédures de sécurité appropriées dans la conservation et la divulgation de vos données à caractère personnel afin d’empêcher l’accès non autorisé par des tiers et d’éviter la perte accidentelle de vos données. Nous limitons l’accès à vos données à caractère personnel aux personnes qui ont réellement besoin d’y accéder pour des raisons professionnelles. Les personnes qui accèdent à vos données sont soumises à un devoir de confidentialité à l’égard de Treize Consulting.
            Nous avons également mis en place des procédures pour traiter toute atteinte présumée à la sécurité des données. Nous vous aviserons, ainsi que toute autorité de contrôle compétente, en cas d’atteinte présumée à la sécurité des données lorsque la loi nous impose de le faire.
            Nous exigeons également des personnes à qui nous transmettons vos données à caractère personnel qu’elles respectent ce qui précède. Malheureusement, la transmission d’informations via Internet n’est pas totalement sécurisée. Nous ne pouvons donc pas garantir la sécurité de vos données à caractère personnel que vous nous avez transmises via Internet. Une telle transmission se fait à vos propres risques et vous reconnaissez et acceptez que nous déclinons toute responsabilité en cas d’utilisation, de distribution, de dommage ou de destruction non autorisés de vos données, sauf dans la mesure où la loi nous oblige à endosser une telle responsabilité. Une fois que nous aurons reçu vos données à 
            caractère personnel, nous appliquerons les mesures de sécurité susmentionnées.
            <br>
        </li>
        <li> <span class="text-bold">
            Quels sont vos droits sur vos données à caractère personnel ?
            </span> 
            <br> Conformément au droit applicable à la protection des données, vous pouvez à tout moment demander l’accès, la rectification, l’effacement et la portabilité de vos données à caractère personnel, ou en restreindre le traitement ou vous y opposer. Un résumé de ces droits est énoncé ci-dessous :
            Votre droit d’accès : le droit de recevoir une copie de vos données à caractère personnel.
            Votre droit de rectification : le droit de nous demander de rectifier toute erreur dans vos données ou de les compléter.
            Votre droit à l’oubli : le droit de nous demander de supprimer vos données à caractère personnel, dans certaines situations.
            Votre droit à la limitation du traitement : le droit de nous demander de limiter le traitement de vos données à caractère personnel, dans certaines circonstances, par exemple si vous contestez l’exactitude des données.
            Votre droit à la portabilité des données : le droit de recevoir les données à caractère personnel que vous nous avez fournies, dans un format structuré, couramment utilisé et lisible par machine et/ou de transmettre ces données à un tiers, dans certaines situations.
            Votre droit d’opposition au traitement : le droit de s’opposer :
            <br> À tout moment pendant le traitement de vos données à caractère personnel;
            <br> Dans certaines autres situations, à la poursuite de notre traitement de vos données à caractère personnel, par exemple le traitement effectué sur la base de nos intérêts légitimes.
            Lorsque le traitement de vos données personnelles est basé sur votre consentement, vous pouvez à tout moment décider de retirer votre consentement au traitement de vos données à caractère personnel notamment lorsque vous ne souhaitez plus recevoir la lettre d’information à laquelle vous vous êtes inscrit. Si votre consentement est retiré, cela ne nous empêche pas de traiter vos données à caractère personnel en nous fondant sur d’autres bases juridiques.
            Si vous ne souhaitez plus recevoir nos informations par mail, nous vous rappelons que vous pouvez révoquer votre consentement à tout moment directement à partir du lien de désabonnement inclus dans chaque message électronique que nous vous envoyons. Si vous le faites, nous mettrons rapidement à jour nos bases de données et prendrons toutes les mesures raisonnables pour répondre à votre demande le plus rapidement possible.
            Vous avez également le droit de déposer une plainte auprès de votre autorité locale de protection des données en cas de violation présumée des règles de protection des données qui vous sont applicables.
            Pour exercer l’un de ces droits, veuillez nous contacter en utilisant les coordonnées ci-dessous (voir « Comment nous contacter »).
            Veuillez noter que si vous exercez l’un des droits mentionnés ci-dessus, il vous sera demandé de nous faire savoir quel droit vous souhaitez exercer et de nous fournir certaines informations (copie d’une carte d’identité, passeport ou autre pièce d’identité légalement reconnue) à des fins d’identification pour traiter votre demande et vous protéger contre les demandes frauduleuses émanant de tiers.
        
        </li>
        
               

        <li> <span class="text-bold">Comment nous contacter ?</span> 
            
                        <br>
            Pour toute question relative à vos données, pour révoquer votre consentement, pour
            poser des questions d’ordre général ou pour porter réclamation, veuillez communiquer
            avec nos équipes :
            TREIZE CONSULTING, est situé Abidjan, Cocody, Angré 8ème Tranche,
            Carrefour prière,
            le site internet de Treize Consulting : via le formulaire de contact prévu à cet effet
            (treizeconsulting@gmail.com).
            Pour les questions ayant précisément trait aux e-mails, nous vous rappelons que vous
            pouvez à tout moment vous désinscrire directement par le lien de « désabonnement »
            contenu dans tous les e-mails que nous vous envoyons.
            Si vous avez des questions ou des préoccupations concernant notre Politique de
            confidentialité ou le traitement des données, vous pouvez contacter :
            treizeconsulting@gmail.com
            <br> 
        </li>


        <li> <span class="text-bold">Modifications de notre Politique de confidentialité
        </span> 
        <br>
            Notre politique de confidentialité reflète nos pratiques actuelles et peut être modifiée et
            mise à jour de temps à autre. Lorsque nous publions des changements à notre Politique
            de confidentialité, nous modifions la « Date d’effet » précisée en haut de ce document
            pour indiquer quand de tels changements sont entrés en vigueur.
            Si nous modifions notre Politique de confidentialité d’une manière substantielle, nous
            vous en informerons par le biais d’un avis de modification au début de celle-ci et sur la
            page d’accueil du site (13.Edu).
            

        </li>
        
       </ol>
    
</div>



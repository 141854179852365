<div class="banner-innerpage" style="background-image:url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
        <!-- Row  -->
        <div class="row justify-content-center">
            <!-- Column -->
            <div class="col-lg-9 col-md-6 align-self-center text-center no-trans">
                <h1 class="title">MODIFICATION DU LA PUBLICATION</h1>
               <!--  <h5 class="subtitle op-5">We are Small Team of Creative People working together</h5>-->
            </div>
            <!-- Column --> 
        </div>
    </div>
</div>

<div class="container spacer">
    <div class="row justify-content-center">
        <div class="col-md-8 no-trans">

            <div *ngIf=editPost>
                <h4 class="mb-3">Detail de la publication</h4>

                <form [formGroup]="editPost" (ngSubmit)="onSubmit()">

                    <div class="form-group">
                        <label>Changer l'image </label>
                        <br>
                        <img [src]='editImage' class="rounded-circle image cursor-pointer" alt="" height="100px"
                            width="100px" (click)='filevar.click()'>
                        <input type="file" class="form-control" (change)='preview(filevar.files)' #filevar>

                    </div>

                    <div class="form-group">
                        <label>Titre </label>
                        <input type="text" class="form-control" formControlName="titre" placeholder="publication">
                    </div>


                    <div class="form-group">
                        <label>Sous titre </label>
                        <input type="text" class="form-control" formControlName="sousTitre"
                            placeholder="Sous titre du publication">
                    </div>

                    <div class="form-group">
                        <label>Description</label>
                        <textarea required class="form-control" rows="5" formControlName="descrition"
                            placeholder="Detail de la publication">
                        </textarea>
                    </div>


                    <div class="modal-footer">
                        <button *ngxPermissionsOnly="['Modifier-Accueil']" type="submit" class="btn btn-primary" [disabled]="editPost?.invalid">MODIFISER </button>

                        <button *ngxPermissionsOnly="['Supprimer-Accueil']"  type="button" class="btn btn-danger ml-2" (click)="deleteBtnClick()">SUPPRIMER </button>
                    </div>
                </form>
            </div>


            <!-- =================
            <form action="/action_page.php">
                <div class="form-group">
                  <label for="email">Email:</label>
                  <input type="email" class="form-control" id="email" placeholder="Enter email" name="email">
                </div>
                <div class="form-group">
                  <label for="pwd">Password:</label>
                  <input type="password" class="form-control" id="pwd" placeholder="Enter password" name="pswd">
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="remember"> Remember me
                  </label>
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
              </form>
            ====================== -->

            <!-- <div *ngIf=editPost>
                <h4 class="mb-3">Preview</h4>
                <form>
                    <div class="form-group">
                        <textarea class="form-control" rows="10" placeholder="Post blog Detail">
                            {{editPost.get('heading')?.value}}

                            {{editPost.get('subHeading')?.value}}
                                                
                            {{editPost.get('blogDetail')?.value}}
                        </textarea>
                    </div>
                </form>

            </div> -->

            <!-- <div class="text-right">
                <button type="button" class="btn btn-danger" (click)="deleteBtnClick()">DELETE POST</button>    
            </div> -->

        </div>
    </div>
</div>
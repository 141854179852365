import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService  } from '../../api/http.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ServiceblogService } from '../blog/blog-service.service';
import { Documents } from '../model/model';
import { Blog, Publication } from '../blog/blog-type';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bibliotheque',
  templateUrl: './bibliotheque.component.html',
  styleUrls: ['./bibliotheque.component.css']
})
export class BibliothequeComponent implements OnInit {

  b: Blog = new Blog();
  pub :Publication =new Publication();
  @ViewChild('content', { static: false }) private content: any;



  img : any;
 // img = 'assets/images/contact/2.jpg';
  imagePath='../assets/images/innerpage/about-us.jpg';

  docDetail: Documents[] = [];

  // info paiyement

  URL_BASE = "https://paytech.sn/api";
  API_KEY='1afac858d4fa5ec74e3e3734c3829793eb6bd5f4602c84ac4a5069369812915e';
  API_SECRET='96bc36c11560f2151c4b43eee310cefabc2e9e9000f7e315c3ca3d279e3f98ac';
  currentUser : any ;
  currentUserSting : any ;
  page = 1;
  pageSize = 6;
  constructor(
    public service: ServiceblogService,
    public router: Router,
    public http: HttpClient,
    private toastr: ToastrService,
    public httpService: HttpService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
    this.spinner.show();
   
  let rq ={
    "user":"1",
    "isSimpleLoading":false,
    "data":{
          "extensionParam":{
              "operator":"IN",
              "datas":["pdf","doc", "docx", "xls", "PDF"]
          }
    }  
  }
    this.httpService.get('documents').subscribe((res: any) => {
     
      if(res.hasError==false && res.items ){
        this.service.pagination.count = res['count'];
        this.service.Documents = res['items'];
      for(var i =0; i<this.service.Documents.length ; i++){
        this.service.Documents[i].image ='assets/images/document/Images.jpeg';
        if(this.service.Documents[i].typeDocumentCode=='Cours') this.service.Documents[i].image ='assets/images/document/Cours.jpg';
        if(this.service.Documents[i].typeDocumentCode=='Devoirs') this.service.Documents[i].image ='assets/images/document/Devoirs.jpeg';
        if(this.service.Documents[i].typeDocumentCode=='Concours') this.service.Documents[i].image ='assets/images/document/Concours.jpg';
        if(this.service.Documents[i].typeDocumentCode=='Corriges') this.service.Documents[i].image ='assets/images/document/Corriges.png';
        if(this.service.Documents[i].typeDocumentCode=='Excercices') this.service.Documents[i].image ='assets/images/document/Excercices.jpg';
        
       
      }
      }else{
        this.service.Documents=[];
      }
      
      this.spinner.hide();
   }
  );
  }
  
  loginClick() {
    this.router.navigate(['/login']);
  }

  newDocument() {
    this.router.navigate(['/post']);
  }

  viewDetail(id: number) {
    this.router.navigate(['/bibliothequeDetail', id]);
  }

  
  addBibliotheque() {
    this.router.navigate(['/newbibliotheque']);
  }


  download(id: number) {
    this.spinner.show();

    let paymentRequestUrl = "https://paytech.sn/api/payment/request-payment";
    //let fetch = require('node-fetch');
    let params = {
    item_name:"Iphone 7",
    item_price:"101",
    currency:"XOF",
    ref_command:"doc-1",
    command_name:"Paiement test PayTech",
    env:"test",
    //ipn_url:"https://domaine.com/ipn",
   // success_url:"https://domaine.com/success",
    //cancel_url:"https://domaine.com/cancel",
    custom_field:JSON.stringify({
       custom_fiel1:"value_1",
       custom_fiel2:"value_2",
    })
    };

      let httpsOptions  = {
        headers: new HttpHeaders({
          Accept: "application/json",
          'Content-Type': "application/json",
          API_KEY:"1afac858d4fa5ec74e3e3734c3829793eb6bd5f4602c84ac4a5069369812915e",
          API_SECRET:"96bc36c11560f2151c4b43eee310cefabc2e9e9000f7e315c3ca3d279e3f98ac",
          })
      };

     var body=JSON.stringify(params);
    
    this.httpService.fetch(paymentRequestUrl,body,httpsOptions).subscribe((res: any) => {
      console.log('res',res);
      this.spinner.hide();
   }
  );
  }

  submit(data: NgForm) {
  
    this.b.heading = data.value.heading;
    this.b.subHeading = data.value.subheading;
    this.b.blogDetail = data.value.textBody;
    this.b.image = this.img;
    this.b.blogDate = new Date().toDateString();
    this.service.addPost(this.b);

    this.pub.titre = data.value.heading;
    this.pub.sousTitre = data.value.subheading;
    this.pub.descrition = data.value.textBody;
    this.pub.image = this.img;
    this.pub.blogDate = new Date().toDateString();
    this.pub.titre = data.value.heading;
    this.pub.sousTitre = data.value.subheading;
    this.pub.descrition = data.value.textBody;
    this.pub.image = this.img;
    this.pub.blogDate = new Date().toDateString();
     
     var d =
      {
        "user":"1",
        "datas":[this.pub]  
      }
    this.httpService.create('publication', d )
    .subscribe((res: any) =>  {
      if(res.hasError ==false) this.toastr.success('Succes', 'Opération effectué avec succès');
        else this.toastr.error('Echec', 'Merci de réessayer');
        this.spinner.hide();
        this.open(this.content);
    })
  }

  preview(files: any) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.img = reader.result;
    };
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {     
    }, (reason) => {     
    });
  }



  fclick(){
    this.modalService.dismissAll();
    this.router.navigate(['/']);
  }

  
  onFileSelected(event :any) {
    this.convertFile(event.target.files[0]).subscribe((base64 : any) => {
      const file:File = event.target.files[0];
      this.pub.datasDocument = [
        {
          "typeDocumentCode": "IMAGE",
          "default":true,
          "extension": file.type.split('/')[1],
          "nomFichier": file.name,
          "fichierBase64":base64
        }
      ]
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event :any) => result.next(btoa( event.target.result.toString()));
    return result;
  }

}

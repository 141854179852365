import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Blog } from '../blog-type';
import { ServiceblogService } from '../blog-service.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { Publication } from '../blog-type';
import { HttpService  } from '../../../api/http.service';
import { Observable, ReplaySubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.css'],
  
})
export class NewPostComponent implements OnInit {
  img: any = '';
  b: Blog = new Blog();
  pub :Publication =new Publication();
  @ViewChild('content', { static: false }) private content: any;


  constructor(public service: ServiceblogService, 
    public httpService : HttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public router: Router, private modalService: NgbModal) {
    this.service.isLoggedIn();
  }

  ngOnInit(): void { }

  submit(data: NgForm) {
  
    this.b.heading = data.value.heading;
    this.b.subHeading = data.value.subheading;
    this.b.blogDetail = data.value.textBody;
    this.b.image = this.img;
    this.b.blogDate = new Date().toDateString();
    this.service.addPost(this.b);

    this.pub.titre = data.value.heading;
    this.pub.sousTitre = data.value.subheading;
    this.pub.descrition = data.value.textBody;
    this.pub.image = this.img;
    this.pub.blogDate = new Date().toDateString();
    this.pub.titre = data.value.heading;
    this.pub.sousTitre = data.value.subheading;
    this.pub.descrition = data.value.textBody;
    this.pub.image = this.img;
    this.pub.blogDate = new Date().toDateString();
     
     var d =
      {
        "user":"1",
        "datas":[this.pub]  
      }
    this.httpService.create('publication', d )
    .subscribe((res: any) =>  {
      if(res.hasError ==false) this.toastr.success('Succes', 'Opération effectué avec succès');
        else this.toastr.error('Echec', 'Merci de réessayer');
        this.spinner.hide();
        this.open(this.content);
    })
  }

  preview(files: any) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.img = reader.result;
    };
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {     
    }, (reason) => {     
    });
  }



  fclick(){
    this.modalService.dismissAll();
    this.router.navigate(['/']);
  }


  onFileSelected(event :any) {
    this.convertFile(event.target.files[0]).subscribe((base64 : any) => {
      const file:File = event.target.files[0];
      this.pub.datasDocument = [
        {
          "typeDocumentCode": "IMAGE",
          "default":true,
          "extension": file.type.split('/')[1],
          "nomFichier": file.name,
          "fichierBase64":base64
        }
      ]
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event :any) => result.next(btoa( event.target.result.toString()));
    return result;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceblogService } from '../../blog/blog-service.service';
import { Documents } from '../../model/model';

@Component({
  selector: 'app-detail-bibliotheque',
  templateUrl: './detail-bibliotheque.component.html',
  styleUrls: ['./detail-bibliotheque.component.css']
})
export class DetailBibliothequeComponent implements OnInit {

 
  id: any;
  bibDetail: Documents = new Documents();

  constructor(activatedRouter: ActivatedRoute, public service: ServiceblogService, public router: Router) {
    this.id = activatedRouter.snapshot.paramMap.get('id');
    this.service.isLoggedIn();
  }

  ngOnInit(): void {
    
    if (this.service.Documents.length > 0)
      this.bibDetail = this.service.Documents.filter((x: any) => x.id === +this.id)[0];
      else this.router.navigate([('/login')]);
  }

  loginClick() {
    this.router.navigate([('/login')]);
  }

  newPost() {
    this.router.navigate([('/post')]);
  }

  editPost() {
    if(this.service.loginStatusService)
    this.router.navigate([('/editPost'), this.bibDetail?.id]);
    else
    this.router.navigate([('/login')]);

    
  }


}

<div class="banner-innerpage" style="background-image: url(../assets/images/landingpage/banner-bg2.jpg)">
    <div class="container">
      <!-- Row  -->
      <div class="row justify-content-center">
        <!-- Column -->
        <div class="col-md-6 align-self-center text-center no-trans">
          <h1 class="title">Bibliothèque </h1>
          <h6 class="subtitle op-8">
           Retrouvez les cours , devoirs, excercices et corrigés
          </h6>
        </div>
        <!-- Column -->
      </div>
    </div>
  </div>

<div class="container">
  <div class="row spacer " style="padding-top: 30px;">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-12" style="margin-bottom: 10px;">
          <a class="btn btn-info-gradiant-orange  btn-md btn-arrow  margin-left-3 "   data-toggle="collapse" (click)="addBibliotheque()">
            <span>  <i class="fa fa-plus"></i>
              Nouveau document 
            </span>
        </a>
        </div>
       
        <!-- column -->
        <div class="col-lg-4 col-md-6 no-trans"  *ngFor="let bib of service.Documents " >
          <!-- Card -->
          <div class="card">
            <img [src]="bib.image" alt="wrappixel kit"
              class="card-img-top img-responsive"
            />
            <div class="card-body">
              <h4 class="card-title">{{bib.typeDocumentFk}}</h4>
              <p class="card-text min-parag">
                {{bib.description?.substring(0, 100)}} ...
              </p>
             
              <div col-lg-12 style="padding-right: 0px;" >
                <a  class="btn btn-outline-primary btn-md btn-arrow"
                data-toggle="collapse">
                <span (click)="viewDetail(bib.id)"> Voir details 
                  <i class="ti-arrow-right"></i>
                </span>
              </a>
              <a
              class="btn btn-outline-warning   btn-md btn-arrow  margin-left-3 float-right"   data-toggle="collapse" routerLink="#" >
              <span (click)="download(bib.id)">  Télecharger 
                <i class="fa fa-download"></i>
              </span>
            </a>
          </div>
            </div>
          </div>
          <!-- Card -->
        </div>
  </div>
